import logo from 'assets/logos/g2f_logo.png';
import Profile from 'components/profile';
import { FC } from 'react';
import { NavContainer } from './styled';

const Nav: FC = () => {
    return (
        <>
            {
                !window.location.pathname.includes('/headless/') && 
                <NavContainer>
                    <img height="75px" src={logo} alt="finecta logo" />
                    {window.location.pathname.includes('/app/') && <Profile />}
                </NavContainer>
            }
        </>
    );
};

export default Nav;
