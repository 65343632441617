import loader from 'assets/lottie-animations/finecta_loader.json';
import Lottie from 'lottie-react';
import { FC } from 'react';

type LoaderInPageType = {
    size?: number;
};

const LoaderInPage: FC<LoaderInPageType> = ({ size = 5 }) => (
    <Lottie
        style={{
            height: `${size}vh`
        }}
        loop
        animationData={loader}
        data-testid="loader-in-page"
    />
);

export default LoaderInPage;
