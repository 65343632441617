export const fontWeights = {
    bold: 700,
    medium: 500,
    semiBold: 600,
    regular: 400
};

export const fontSizes = {
    xsmall: '12px',
    small: '14px',
    regular: '16px',
    large: '20px',
    xLarge: '24px'
};

export const font = {
    heading: `h1,
  h2,
  h3,
  h4,
  h5 {font-family: 'Montserrat', sans-serif;}`,
    body: `*:not(h1, h2, h3, h4, h5) {font-family: 'Source Sans Pro', sans-serif;}`
};

export const typography = {
    bodyRegular: `font-size: ${fontSizes.regular}`,
    bodySmallSemiBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.semiBold}`,
    bodySemiBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
    navLink: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}`,
    bodyBold: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.bold}`,
    link: `font-size: ${fontSizes.regular}; font-weight: ${fontWeights.semiBold}; text-decoration: underline; cursor: pointer;`,
    captionRegular: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.regular}`,
    captionBold: `font-size: ${fontSizes.small}; font-weight: ${fontWeights.bold}`
};
