/* eslint-disable react-hooks/exhaustive-deps */
import { IPublicClientApplication, RedirectRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { apiConfig, b2cPolicies, get } from 'api/apiConfig';
import LoaderFullPage from 'components/LoaderFullPage';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

const SignUp: FC = () => {
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    const params = useParams();
    const { instance, accounts } = useMsal();
    let count = 0;

    useEffect(() => {
        if (count === 0 && accounts.length === 0) initSignupSequence();

        count++;
    }, []);

    const initSignupSequence: () => void = async () => {
        if (!params?.invitationId) return;
        setLoadingMessage('Retrieving your invitation details...');
        const regByInviteRes: Response = await get(
            apiConfig.registerByPartnerInvite(params.invitationId),
            ''
        );
        if (!regByInviteRes.ok) return;
        const regByInviteResJson = await regByInviteRes.json();
        const request: RedirectRequest = {
            scopes: [
                `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
            ],
            extraQueryParameters: {
                id_token_hint: regByInviteResJson.idTokenHint,
                campaignId: window.location.hostname,
                displayName: regByInviteResJson.inviteFromDisplayName
            },
            state: `inviteUID=${params.invitationId}`,
            prompt: 'login',
            redirectStartPage: `${window.location.origin}/app/accept/${regByInviteResJson.inviteFrom}`,
            authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority
        };
        await (instance as IPublicClientApplication).loginRedirect(request);
    };

    return <LoaderFullPage detail={loadingMessage} />;
};

export default SignUp;
