import { ConnectorType } from 'utils/interfaces/ConnectorType';
export default class G2FSetup {
    constructor(
        public readonly token: string,
        public readonly redirectPath: string,
        public readonly customerUID: string,
        public readonly platformRedirectPath: string,
        public readonly g2fDomain?: string,
        public readonly connectorTypes?: ConnectorType[],
        public readonly partnerTenantId?: string
    ) {
        if (!this.g2fDomain) this.g2fDomain = 'https://g2f.finecta.io';
        globalThis.g2fDomain = this.g2fDomain;
    }
}
