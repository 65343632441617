import Uploader from 'components/Uploader';
import { apiConfig, post, postFile, put } from 'api/apiConfig';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckIcon from '@mui/icons-material/Check';

import { FC, useState } from 'react';
import {
    CSVConfigurerContainer,
    SelectContainer,
    MessageContainer,
    ErrorMessageContainer,
    SuccessMessageContainer
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { useForm } from 'react-hook-form';
import Select from 'components/forms/inputs/Select';
import { themeColors } from 'assets/theme/style';
import { ISelectData } from 'lib/interfaces';


interface ICSVConfigurer {
    customerUID: string;
    token: string;
}

const CSVConfigurer: FC<ICSVConfigurer> = ({ customerUID, token }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedFiles, setselectedFiles] = useState<File[]>([]);

    const { handleSubmit } = useForm();

    const getCsvConnector = async () => {
        let data = {
            configuration: {}
        };
        setLoading(true);
        await post(apiConfig.addConnectorWithType(customerUID, 'csv'), data, token).then(() => {
            saveDefaultConnectorSchedule();
            setLoading(false);
            postCsvFile();
        });
    };

    const postCsvFile = async () => {
        if (!selectedFiles) {
            return;
        }

        const formData = new FormData();
        formData.append('files', selectedFiles[0]);
        formData.append('type', selectedType);

        setLoading(true);
        await postFile(apiConfig.uploadCustomerFile(customerUID), formData, token).then(
            (response: any) => {
                setLoading(false);
                if (response.status !== 200) {
                    setSuccess(false);
                    setError(true);
                } else {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                    setError(false);
                    setSelectedType('');
                    setselectedFiles([]);
                }
            }
        );
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(apiConfig.updateSchedule(customerUID), {
            schedule: '0 0 0 * * *'
        }, token);

    const fileDropHandler = (acceptedFiles: File[]) => {
        setselectedFiles(acceptedFiles);
    };

    const onSubmit = () => {
        getCsvConnector();
    };

    const options:ISelectData[] = [
        { value: 1, label: 'Invoices' },
        { value: 2, label: 'Payments' },
        { value: 3, label: 'PurchaseOrders' }
    ];

    const handleChange = (selectedOption: any) => {
        setSelectedType(selectedOption);
    };

    const getSelectedName: (value: string) => string = (value) => {
        const getSelectedCsv: ISelectData = options.filter(
            (item: ISelectData) => item.value === value
        )[0];

        return getSelectedCsv?.label || '';
    };

    return loading ? (
        <LoaderInPage />
    ) : (
        <CSVConfigurerContainer
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }}
        >
            {(error || success) && (
                <MessageContainer>
                    {error && (
                        <ErrorMessageContainer>
                            <PriorityHighIcon /> The file could not be uploaded. Please try again.
                        </ErrorMessageContainer>
                    )}
                    {success && (
                        <SuccessMessageContainer>
                            <CheckIcon /> The csv file has been uploaded successfully.
                        </SuccessMessageContainer>
                    )}
                </MessageContainer>
            )}
            <SelectContainer>
                <Select
                    label="Type"
                    background={themeColors.white}
                    menuItems={options}
                    value={selectedType}
                    name={getSelectedName(selectedType)}
                    placeholder="Select Type"
                    changeHandler={(event) => handleChange(event.target.value)}
                />
            </SelectContainer>
            <Uploader
                fileDropHandler={fileDropHandler}
                singlePreviewMode
                acceptedFileTypes={['csv', 'xlsx']}
            />
            <PrimaryButton
                type="submit"
                width="fit-content"
                disabled={selectedFiles.length === 0 || selectedType === ''}
            >
                Save
            </PrimaryButton>
        </CSVConfigurerContainer>
    );
};

export default CSVConfigurer;
