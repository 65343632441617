import logoXero from '../assets/xero.png';
import logoFreshbooks from '../assets/FreshBooks-logo.jpg';
import logoMyob from '../assets/Myob_Logo.png';
import logoZoho from '../assets/zoho.png';
import logoSage from '../assets/sage.png';
import logoQuickbooks from '../assets/quickbooks.png';
import { authConnectors } from '../lib/constants';

const authConfig = {
    origin: window.location.origin,
    token: '',
    redirectPath: '',
    getConnectorAuthUri: (
        state: any,
        connectorName: string | number,
        redirectPath: string,
        clientId: string
    ) =>
        `${authConfig.connectors[connectorName].baseUrl}response_type=${authConfig.connectors[connectorName].responseType}&client_id=${clientId}&redirect_uri=${redirectPath}&scope=${authConfig.connectors[connectorName].scope}&state=${state}`,
    connectors: authConnectors
};

const connectorsConfig = {
    connectors: {
        xero: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                authConfig.getConnectorAuthUri(state, 'xero', redirectPath, clientId),
            logo_url: logoXero,
            display_name: 'Xero',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'xero'
        },
        freshbooks: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                authConfig.getConnectorAuthUri(state, 'freshbooks', redirectPath, clientId),
            logo_url: logoFreshbooks,
            display_name: 'Freshbooks',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'freshbooks'
        },
        myob: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                authConfig.getConnectorAuthUri(state, 'myob', redirectPath, clientId),
            logo_url: logoMyob,
            display_name: 'MYOB',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'myob'
        },
        zoho: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                `${authConfig.getConnectorAuthUri(
                    state,
                    'zoho',
                    redirectPath,
                    clientId
                )}&access_type=offline&prompt=consent`,
            logo_url: logoZoho,
            location: 'location',
            display_name: 'Zoho Invoices',
            logo_width: '90px',
            reusable: false,
            accounts: false,
            connected: false,
            type: 'zohoinvoice'
        },
        quickbooks: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                authConfig.getConnectorAuthUri(state, 'quickbooks', redirectPath, clientId),
            logo_url: logoQuickbooks,
            tenant_id: 'realmId',
            display_name: 'Quickbooks',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'quickbooks'
        },
        zohobooks: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                `${authConfig.getConnectorAuthUri(
                    state,
                    'zohobooks',
                    redirectPath,
                    clientId
                )}&access_type=offline&prompt=consent`,
            logo_url: logoZoho,
            location: 'location',
            display_name: 'Zoho Books',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'zohobooks'
        },
        sage: {
            get_auth_url: (state: any, redirectPath: string, clientId: string) =>
                authConfig.getConnectorAuthUri(state, 'sage', redirectPath, clientId),
            logo_url: logoSage,
            display_name: 'Sage',
            logo_width: '90px',
            reusable: false,
            accounts: true,
            connected: false,
            type: 'sage'
        },
        edi: {
            display_name: 'EDI',
            logo_width: '90px',
            reusable: false,
            accounts: false,
            connected: false,
            type: 'edi'
        },
        fides: {
            display_name: 'FIDES',
            logo_width: '90px',
            reusable: false,
            accounts: false,
            connected: false,
            type: 'fides'
        },
        tr: {
            display_name: 'Thomson Reuters',
            logo_width: '90px',
            reusable: false,
            accounts: false,
            connected: false,
            type: 'tr'
        },
        csv: {
            display_name: 'CSV',
            logo_width: '90px',
            reusable: false,
            accounts: false,
            connected: false,
            type: 'csv'
        }
    },
    campaigns: {
        default: {
            hostname: 'tlcfinance.global',
            style: 'https://g2fauth.finecta.io/Content/css/default.css',
            favicon: 'https://g2f.finecta.io/favicon.ico',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/HipsterLogo.png'
        },
        clarency: {
            hostname: 'clarency.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/bizClarency.gif',
            style: 'https://g2fauth.finecta.io/Content/css/clarency.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/clarency.ico'
        },
        liquiditydemo: {
            hostname: 'liquiditydemo.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/liquiditylogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/liquiditydemo.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/liquidity.ico'
        },
        acmedemo: {
            hostname: 'acmedemo.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/acmelogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/acmedemo.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/acme.ico'
        },
        mp: {
            hostname: 'mp.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/MPNLogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/mp.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/mp.ico'
        },
        solv: {
            hostname: 'solv.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/solvlogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/solv.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/solv.ico'
        },
        harbor: {
            hostname: 'harbor.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/harbor-logo.png',
            style: 'https://g2fauth.finecta.io/Content/css/harbor.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/harbor.ico'
        },
        furious: {
            hostname: 'furious.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/fflogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/furious.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/harbor.ico'
        },
        hipster: {
            hostname: 'hipster.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/HipsterLogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/hipster.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/harbor.ico'
        },
        ngrok: {
            hostname: 'ngrok.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/choice-international-landscape.gif',
            style: 'https://g2fauth.finecta.io/Content/css/choice.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/choice.ico'
        },
        choice: {
            hostname: 'choice.tlcfinance.global',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/choice-international-landscape.gif',
            style: 'https://g2fauth.finecta.io/Content/css/choice.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/choice.ico'
        },
        stagingmpn: {
            hostname: 'tlccstagingmpn-g2f-as.azurewebsites.net',
            logo: 'https://corporateportaladb2c.blob.core.windows.net/public/MPNLogo.png',
            style: 'https://g2fauth.finecta.io/Content/css/mp.css',
            favicon: 'https://corporateportaladb2c.blob.core.windows.net/public/mp.ico'
        }
    }
};

const x = {
    // Add common config values here
    MAX_ATTACHMENT_SIZE: 5000000,
    ...connectorsConfig
};

export default x;
