/* eslint-disable react-hooks/exhaustive-deps */
import { FC, useEffect, useState } from 'react';
import {
    ConnectionsContainer,
    ConnectionsCustomerContainer,
    ConnectionsHeadingContainer,
    ConnectionsWrapper
} from './styled';
import { SilentRequest } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { useNavigate } from 'react-router';
import { apiConfig, b2cPolicies, get } from 'api/apiConfig';
import { PrimaryButton } from 'components/common/buttons';
import LoaderFullPage from 'components/LoaderFullPage';
import Typography from 'components/typography';
import { authResult } from 'lib/authConfig';

const Connections: FC = () => {
    const { instance, accounts, inProgress }: IMsalContext = useMsal();
    const navigate = useNavigate();
    const [loading, setLoading] = useState<boolean>(false);
    const [customers, setCustomers] = useState<any[]>([]);

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ],
        extraQueryParameters: {
            campaignId: window.location.hostname
        },
        authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority,
        account: accounts[0]
    };

    useEffect(() => {
        setLoading(true);
        if (accounts.length === 0) {
            navigate('/?unauthenticated=true');
        }

        if (accounts.length > 0) {
            loadConnectors();
        }
    }, [inProgress, accounts]);

    const loadConnectors: () => void = async () => {
        const token = await authResult(request, instance);
        if (!token) {
            navigate('/?unauthenticated=true');
        } else {
            const getFlowsRes: Response = await get(apiConfig.flows, token.accessToken);
    
            if (!getFlowsRes.ok) return;
    
            const getFlowsResJson = await getFlowsRes.json();
    
            setCustomers(getFlowsResJson.linkedFlows);
            setLoading(false);
        }
    };

    return loading ? (
        <LoaderFullPage />
    ) : (
        <ConnectionsWrapper>
            <ConnectionsHeadingContainer>
                {customers.length > 0 ? (
                    <Typography tag="subheading1">
                        You have more than one connector. Which one would you like to use:
                    </Typography>
                ) : (
                    <Typography tag="subheading1">
                        You have no connectors configured. Please contact to your partner.
                    </Typography>
                )}
            </ConnectionsHeadingContainer>

            {!loading && customers && (
                <ConnectionsContainer>
                    {customers.map((c) => (
                        <ConnectionsCustomerContainer key={c.id}>
                            <PrimaryButton
                                clickHandler={() => {
                                    navigate(`/app/customer/${c.id}`);
                                }}
                            >
                                {c.name}
                            </PrimaryButton>
                        </ConnectionsCustomerContainer>
                    ))}
                </ConnectionsContainer>
            )}
        </ConnectionsWrapper>
    );
};

export default Connections;
