import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { ButtonStyled } from 'components/common/buttons/styled';

export const PrimaryButtonStyled = styled(ButtonStyled)<ButtonProps>`
    padding: ${({ padding }) => padding};
    border-radius: ${({ borderRadius }) => borderRadius};
    gap: ${({ gap }) => gap};
    min-width: ${({ minWidth }) => minWidth};
    width: ${({ width }) => width};
    height: ${({ height }) => height};
`;
