import { Visibility, VisibilityOff } from '@mui/icons-material';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    TextField
} from '@mui/material';
import { apiConfig, post, put } from 'api/apiConfig';
import { inputSxStyles } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { FC, useState } from 'react';

interface IThomsonReutersConfigurer {
    customerUID: string;
    partnerTenantId: string;
    token: string;
    redirectUrl: string;
}

const ThomsonReutersConfigurer: FC<IThomsonReutersConfigurer> = ({
    customerUID,
    partnerTenantId,
    token,
    redirectUrl
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [extractUrl, setExtractUrl] = useState<string>('');
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');
    const [identifiers, setIdentifiers] = useState<string[]>([]);
    const [addRateChangeUrl, setAddRateChangeUrl] = useState<string>('');
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const handleClickShowPassword: () => void = () => setShowPassword((show) => !show);

    const handleMouseDownPassword: (event: React.MouseEvent<HTMLButtonElement>) => void = (event) =>
        event.preventDefault();

    const saveConnector: () => void = async () => {
        setLoading(true);
        const addConnectorTypeResponse = await post(
            apiConfig.addConnectorWithType(customerUID, 'tr'),
            {
                customerUid: customerUID,
                connectorType: 'tr',
                partnerTenantId,
                configuration: {
                    extractUrl: extractUrl,
                    username: username,
                    password: password,
                    identifiers: identifiers,
                    addRateChangeUrl: addRateChangeUrl
                }
            },
            token
        );

        if (!addConnectorTypeResponse.ok) throw Error('Can not add Thomson Reuters Connector Type');

        saveDefaultConnectorSchedule();
        setLoading(false);
        setSaved(true);
        window.location.href = redirectUrl + '&isComplete=true';
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(
            apiConfig.updateSchedule(customerUID),
            {
                schedule: '0 0 0 * * *'
            },
            token
        );

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: loading ? '100%' : 'fit-content'
            }}
        >
            {!loading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '16px'
                    }}
                >
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={extractUrl}
                        sx={inputSxStyles}
                        id="extractUrl"
                        label="API Url"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setExtractUrl(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={username}
                        sx={inputSxStyles}
                        id="username"
                        label="Username"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setUsername(event.target.value);
                        }}
                    />
                    <FormControl
                        sx={inputSxStyles}
                        fullWidth
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassword(event.target.value);
                        }}
                    >
                        <InputLabel htmlFor="password">Password</InputLabel>
                        <OutlinedInput
                            id="password"
                            type={showPassword ? 'text' : 'password'}
                            value={password}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label="toggle password visibility"
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                            label="Password"
                        />
                    </FormControl>
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={addRateChangeUrl}
                        sx={inputSxStyles}
                        id="rateChangeUrl"
                        label="Add Rate Change Url"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setAddRateChangeUrl(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={identifiers}
                        sx={inputSxStyles}
                        id="identifiers"
                        label="Instrument Identifiers (CSV)"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setIdentifiers(
                                event.target.value
                                    .split(',')
                                    .map((x) => x.trim())
                                    .filter((x) => x)
                            );
                        }}
                    />
                    <PrimaryButton
                        disabled={loading || saved}
                        clickHandler={saveConnector}
                        width="fit-content"
                    >
                        {loading ? <LoaderInPage /> : saved ? 'Saved!' : 'Save'}
                    </PrimaryButton>
                </div>
            )}
        </div>
    );
};

export default ThomsonReutersConfigurer;
