import { themeColors } from 'assets/theme/style';
import { FC, ReactNode } from 'react';
import './typography.css';

enum JSXTagEnum {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    h6 = 'h6',
    p = 'p',
    span = 'span'
}

enum TagsEnum {
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    h4 = 'h4',
    h5 = 'h5',
    subheading1 = 'h6',
    subheading2 = 'h6',
    body1 = 'p',
    body1large = 'p',
    body2 = 'p',
    p = 'p',
    span = 'span'
}

enum FontWeightsEnum {
    semibold = '600',
    bold = '700',
    regular = '400'
}

const FontColorsLookup = {
    grey: themeColors.darkGrey,
    primary: themeColors.primary,
    secondary: themeColors.secondary,
    accent: themeColors.accent
};

type TypographyProps = {
    tag: keyof typeof TagsEnum;
    children: ReactNode;
    props?: any;
    fontWeight?: keyof typeof FontWeightsEnum;
    textColor?: keyof typeof FontColorsLookup;
};

const Typography: FC<TypographyProps> = ({ tag, children, fontWeight, textColor, ...props }) => {
    const Component: keyof typeof JSXTagEnum = tag ? TagsEnum[tag] : TagsEnum['p'];
    return (
        <Component
            className={`finecta-typography--variant-${tag} finecta--${fontWeight}`}
            style={{ color: textColor ? FontColorsLookup[textColor] : '' }}
            {...props}
        >
            {children}
        </Component>
    );
};

export default Typography;
