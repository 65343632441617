import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import styled from 'styled-components';

interface FileUpload {
  boderColor?: string;
}

export const UploaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const UploaderFilesListContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;
`;

export const UploaderFilesListItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const UploaderFilesListItemLeftSegment = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const UploaderFilesIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploaderFilesFilename = styled.p`
  ${typography.bodySemiBold};
  color: ${themeColors.secondary};
`;

export const FileUploadContainer = styled.div<FileUpload>`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${themeColors.white};
  border-radius: 12px;
  border: 1px dashed ${({ boderColor }) => boderColor || themeColors.body};
  padding: 16px 12px;
  gap: 12px;
`;

export const FileUploadIconContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const UploaderFilesDeleteIconContainer = styled(FileUploadIconContainer)`
  cursor: pointer;
`;

export const FileUploadButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const FileUploadText = styled.h5``;

export const FileUploadButtonText = styled.p`
  ${typography.bodySemiBold};
`;
