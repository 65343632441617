import {
    ErrorMessageContainer,
    MessageContainer,
    NoticeContainer,
    SFTPConfigurerContainer,
    SelectContainer,
    SuccessMessageContainer,
    TextContainer
} from './styled';
import { FC, useEffect, useState } from 'react';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import { apiConfig, post, put } from 'api/apiConfig';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import TextInput from 'components/forms/inputs/TextInput';
import Select from 'components/forms/inputs/Select';
import CheckIcon from '@mui/icons-material/Check';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { ISelectData } from 'lib/interfaces';
import { SelectChangeEvent } from '@mui/material';


const csvOptions: ISelectData[] = [
    { label: 'csv', value: 1 }
];

interface ISFTPConfigurer {
    customerUID: string;
    token: string;
}

const SFTPConfigurer: FC<ISFTPConfigurer> = ({ customerUID, token }) => {
    const [selectedFormat, setSelectedFormat] = useState<number>(0);
    const [receivingSftpHostname, setReceivingSftpHostname] = useState<string>('');
    const [receivingSftpUsername, setReceivingSftpUsername] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [invoicesPath, setInvoicesPath] = useState<string>('');
    const [paymentsPath, setPaymentsPath] = useState<string>('');
    const [purchaseOrdersPath, setPurchaseOrdersPath] = useState<string>('');
    const [validPaths, setValidPaths] = useState<boolean>(false);

    const [saved, setSaved] = useState<boolean>(false);

    const handleChangeFormat = (selectedOption: SelectChangeEvent<number>) => {
        setSaved(false);
        setSelectedFormat(selectedOption.target.value as number);
    };

    const getSelectedName: (value: number) => string = (value) => {
        const getSelectedCsv: ISelectData = csvOptions.filter(
            (item: ISelectData) => item.value === value
        )[0];

        return getSelectedCsv?.label || '';
    };

    const resetData: () => void = () => {
        setSelectedFormat(0);
        setReceivingSftpHostname('');
        setReceivingSftpUsername('');
        setInvoicesPath('');
        setPaymentsPath('');
        setPurchaseOrdersPath('');
    };

    useEffect(() => {
        invoicesPath &&
            paymentsPath &&
            purchaseOrdersPath &&
            setValidPaths(
                new Set([invoicesPath, paymentsPath, purchaseOrdersPath]).size < 3 ? false : true
            );
    }, [invoicesPath, paymentsPath, purchaseOrdersPath]);

    const saveConnector = async () => {
        let data = {
            customerUid: customerUID,
            connectorType: 'csv',
            configuration: {
                SftpConfig: {
                    selectedFormat,
                    receivingSftp: {
                        hostname: receivingSftpHostname,
                        username: receivingSftpUsername
                    },
                    invoicesPath: invoicesPath,
                    purchaseOrdersPath: paymentsPath,
                    paymentsPath: purchaseOrdersPath
                }
            }
        };
        setLoading(true);
        await post(apiConfig.addConnectorWithType(customerUID, 'csv'), data, token).then(() => {
            saveDefaultConnectorSchedule();
            setLoading(false);
            setSaved(true);
            setTimeout(() => {
                setSaved(false);
            }, 5000);
            resetData();
        });
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(apiConfig.updateSchedule(customerUID), {
            schedule: '0 0 0 * * *'
        }, token);

    return (
        <SFTPConfigurerContainer>
            {loading && <LoaderInPage />}
            {saved && (
                <MessageContainer>
                    <SuccessMessageContainer>
                        <CheckIcon /> Sftp Configuration has been saved successfully.
                    </SuccessMessageContainer>
                </MessageContainer>
            )}
            <SelectContainer>
                <Select
                    label="Format"
                    background={themeColors.white}
                    menuItems={csvOptions}
                    value={selectedFormat}
                    disabled={loading}
                    name={getSelectedName(selectedFormat)}
                    placeholder="File Format"
                    changeHandler={(event:SelectChangeEvent<number>) => handleChangeFormat(event)}
                />
            </SelectContainer>

            <SelectContainer>
                <TextInput
                    label="Hostname"
                    htmlFor="rhostname"
                    placeholder="Receiving SFTP Hostname"
                    defaultValue={receivingSftpHostname}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setReceivingSftpHostname(event.target.value);
                    }}
                    disabled={loading}
                />
            </SelectContainer>

            <SelectContainer>
                <TextInput
                    label="Username"
                    htmlFor="rusername"
                    placeholder="Receiving SFTP Username"
                    defaultValue={receivingSftpUsername}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setReceivingSftpUsername(event.target.value);
                    }}
                    disabled={loading}
                />
            </SelectContainer>

            <TextContainer>
                <TextInput
                    label="Invoice Files Path"
                    htmlFor="invoicesPath"
                    placeholder="Path where invoice files are taken from"
                    defaultValue={invoicesPath}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setInvoicesPath(event.target.value);
                    }}
                    disabled={loading}
                />
            </TextContainer>

            <TextContainer>
                <TextInput
                    label="Payment Files Path"
                    htmlFor="paymentsPath"
                    placeholder="Path where payment files are taken from"
                    defaultValue={paymentsPath}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setPaymentsPath(event.target.value);
                    }}
                    disabled={loading}
                />
            </TextContainer>

            <TextContainer>
                <TextInput
                    label="Purchase Order Path"
                    htmlFor="poPath"
                    placeholder="Path where purchase order files are taken from"
                    defaultValue={purchaseOrdersPath}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setPurchaseOrdersPath(event.target.value);
                    }}
                    disabled={loading}
                />
            </TextContainer>
            {!validPaths && (
                <MessageContainer>
                    <ErrorMessageContainer>
                        <PriorityHighIcon /> File paths for each file type must be a unique location
                        on the SFTP server!
                    </ErrorMessageContainer>
                </MessageContainer>
            )}
            <NoticeContainer>
                <div>
                    <label>Notice</label>
                </div>
                <p>
                    You must <a href="/finecta-public-key">download</a> our public key and apply it
                    to your SFTP server in order to enable us to collect your files.
                </p>
            </NoticeContainer>

            <PrimaryButton
                clickHandler={saveConnector}
                width="fit-content"
                disabled={
                    receivingSftpHostname.length === 0 ||
                    receivingSftpUsername.length === 0 ||
                    invoicesPath.length === 0 ||
                    paymentsPath.length === 0 ||
                    purchaseOrdersPath.length === 0 ||
                    !validPaths ||
                    selectedFormat === 0 ||
                    loading
                }
            >
                Save
            </PrimaryButton>
        </SFTPConfigurerContainer>
    );
};

export default SFTPConfigurer;
