import { Checkbox, SelectChangeEvent } from '@mui/material';
import TextInput from 'components/forms/inputs/TextInput';
import { apiConfig, post, put } from 'api/apiConfig';
import { themeColors } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { FC, useState } from 'react';
import { TextContainer, SelectContainer, EdiCheckboxContainer, MappingCheckboxLabel, SFTPConfigurerContainer, MessageContainer, SuccessMessageContainer, NoticeContainer } from './styled';
import CheckIcon from '@mui/icons-material/Check';
import FormsSelect from 'components/forms/inputs/Select';

interface IEDIConfigurer {
    customerUID: string;
    partnerTenantId: string;
    token: string;
    redirectUrl: string;
}

interface IEdiOptions {
    value: string;
    label: string;
}

const ediOptions: IEdiOptions[] = [
    { value: 'X12', label: 'X12' },
    { value: 'EDIFACT', label: 'Edifact' }
];

const EDIConfigurer: FC<IEDIConfigurer> = ({
    customerUID,
    partnerTenantId,
    token,
    redirectUrl
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [selectedFormat, setSelectedFormat] = useState<string>('');
    const [receivingSftpHostname, setReceivingSftpHostname] = useState<string>('');
    const [purchaseOrdersPath, setPurchaseOrdersPath] = useState<string>('');
    const [acknowledgementSftpHostname, setAcknowledgementSftpHostname] = useState<string>('');
    const [receivingSftpUsername, setReceivingSftpUsername] = useState<string>('');
    const [acknowledgementSftpUsername, setAcknowledgementSftpUsername] = useState<string>('');
    const [SendAcknowledgementSftp, setSendAcknowledgementSftp] = useState<boolean>(false);

    const handleChangeFormat = (selectedOption: SelectChangeEvent<string>) => {
        setSaved(false);
        setSelectedFormat(selectedOption.target.value as string);
    };

    const getSelectedFormat: (value: string) => string = (value) => {
        const getSelectedFotmat: IEdiOptions = ediOptions.filter(
            (item: IEdiOptions) => item.value === value
        )[0];

        return getSelectedFotmat?.label || '';
    };

    const saveConnector: () => Promise<void> = async () => {
        setLoading(true);
        const addConnectorWithTypeResponse = await post(
            apiConfig.addConnectorWithType(customerUID, 'edi'),
            {
                customerUid: customerUID,
                connectorType: 'edi',
                partnerTenantId,
                configuration: {
                    selectedFormat,
                    receivingSftp: {
                        hostname: receivingSftpHostname,
                        username: receivingSftpUsername,
                        invoicesPath: 'invoices',
                        purchaseOrdersPath: purchaseOrdersPath,
                        paymentsPath: 'payments'
                    },
                    acknowledgementSftp: {
                        hostname: acknowledgementSftpHostname,
                        username: acknowledgementSftpUsername,
                        path: '/'
                    }
                }

            },
            token
        );

        if (!addConnectorWithTypeResponse.ok) throw Error('Can not add EDI connector');

        saveDefaultConnectorSchedule();
        setLoading(false);
        setSaved(true);
        window.location.href = redirectUrl + '&isComplete=true';
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(apiConfig.updateSchedule(customerUID), {
            schedule: '0 0 0 * * *'
        }, token);

    return (
        <SFTPConfigurerContainer>
            {loading && <LoaderInPage />}
            {saved && (
                <MessageContainer>
                    <SuccessMessageContainer>
                        <CheckIcon /> Sftp Configuration has been saved successfully.
                    </SuccessMessageContainer>
                </MessageContainer>
            )}

            <EdiCheckboxContainer>
                <MappingCheckboxLabel>Send Acknowledgements</MappingCheckboxLabel>
                <Checkbox
                    id="ediFormat"
                    name="ediFormat"
                    disableRipple
                    checked={
                        SendAcknowledgementSftp
                    }
                    sx={{
                        color: 'rgba(0, 0, 0, 0.23)',
                        '&.MuiCheckbox-root': {
                            justifyContent: 'flex-start'
                        },
                        '&.Mui-checked': {
                            color: themeColors.primary
                        },
                        '&.Mui-disabled': {
                            color: themeColors.darkGrey
                        },
                        '&:hover': { bgcolor: 'transparent' }
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSendAcknowledgementSftp(event.target.checked);
                    }}
                />
            </EdiCheckboxContainer>

            <SelectContainer>
                <FormsSelect
                    label="Format"
                    background={themeColors.white}
                    menuItems={ediOptions}
                    value={selectedFormat}
                    disabled={loading}
                    name={getSelectedFormat(selectedFormat)}
                    placeholder="Edi Format"
                    changeHandler={(event: SelectChangeEvent<string>) => handleChangeFormat(event)}
                />
            </SelectContainer>
            <TextContainer>
                <TextInput
                    defaultValue={receivingSftpHostname}
                    htmlFor="rhostname"
                    label="Receiving SFTP Hostname"
                    placeholder="Receiving SFTP Hostname"
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setReceivingSftpHostname(event.target.value);
                    }}
                />
            </TextContainer>
            <TextContainer>

                <TextInput
                    defaultValue={receivingSftpUsername}
                    htmlFor="rusername"
                    label="Receiving SFTP Username"
                    placeholder="Receiving SFTP Username"
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setReceivingSftpUsername(event.target.value);
                    }}
                />
            </TextContainer>
            <TextContainer>
                <TextInput
                    label="Purchase Order Path"
                    htmlFor="poPath"
                    placeholder="Path where purchase order files are taken from"
                    defaultValue={purchaseOrdersPath}
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setSaved(false);
                        setPurchaseOrdersPath(event.target.value);
                    }}
                    disabled={loading}
                />
            </TextContainer>
            <TextContainer>
                <TextInput
                    defaultValue={acknowledgementSftpHostname}
                    htmlFor="ahostname"
                    label="Acknowledgement SFTP Hostname"
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAcknowledgementSftpHostname(event.target.value);
                    }}
                />
            </TextContainer>
            <TextContainer>
                <TextInput
                    defaultValue={acknowledgementSftpUsername}
                    htmlFor="ausername"
                    label="Acknowledgement SFTP Username"
                    changeHandler={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setAcknowledgementSftpUsername(event.target.value);
                    }}
                />
            </TextContainer>
            <NoticeContainer>
                <div>
                    <label>Notice</label>
                </div>
                <p>
                    You must <a href="/finecta-public-key">download</a> our public key and apply it
                    to your SFTP server in order to enable us to collect your files.
                </p>
            </NoticeContainer>
            <PrimaryButton
                clickHandler={saveConnector}
                width="fit-content"
                disabled={
                    receivingSftpHostname.length === 0 ||
                    receivingSftpUsername.length === 0 ||
                    selectedFormat.length === 0 ||
                    purchaseOrdersPath.length === 0 ||
                    loading
                }
            >
                Save
            </PrimaryButton>
        </SFTPConfigurerContainer>
    );
};

export default EDIConfigurer;
