import { FC } from 'react';
import { LandingContainer, LandingHeadingContainer} from './styled';

const LandingPage: FC = () => (
    <LandingContainer>
        <LandingHeadingContainer>
            Login Successful - You can now close this page.
        </LandingHeadingContainer>
    </LandingContainer>
);

export default LandingPage;