import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const ConnectionsWrapper = styled(G2FSSContainer)``;

export const ConnectionsContainer = styled.div`
    display: flex;
    gap: 24px;
`;

export const ConnectionsHeadingContainer = styled.div`
    margin-bottom: 24px;
    display: flex;
    flex-direction: column;
    gap: 24px;
`;

export const ConnectionsCustomerContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    align-items: center;
    justify-content: center;
    position: relative;
`;
