/* eslint-disable react-hooks/exhaustive-deps */
import { SilentRequest } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import { Alert, TextField } from '@mui/material';
import { apiConfig, b2cPolicies, get } from 'api/apiConfig';
import { inputSxStyles } from 'assets/theme/style';
import ERPSelectionTiles from 'components/ERPSelectionTiles';
import { PrimaryButton } from 'components/common/buttons';
import Typography from 'components/typography';
import G2FSetup from 'lib/setup';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConnectorType } from 'utils/interfaces/ConnectorType';
import {
    CustomerBottomContainer,
    CustomerButtonContainer,
    CustomerContainer,
    CustomerReportContainer,
    CustomerTopContainer
} from './styled';
import LoaderFullPage from 'components/LoaderFullPage';
import { authResult } from 'lib/authConfig';

const Customer: FC = () => {
    const { instance, accounts, inProgress }: IMsalContext = useMsal();
    const params = useParams();
    const navigate = useNavigate();
    const [token, setToken] = useState<string>('');
    const [tokenExpiry, setTokenExpiry] = useState<Date | null>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [processing, setProcessing] = useState<boolean>(true);
    const [customerUid, setCustomerUid] = useState<string>('');
    const [partnerUid, setPartnerUid] = useState<string>('');
    const [accountingSystem, setAccountingSystem] = useState<string>('');
    const [connectorTypes, setConnectorTypes] = useState<ConnectorType[]>([]);
    const [connectionComplete, setConnectionComplete] = useState<boolean>(false);
    let count = 0;
    let accountCount = 0;

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ],
        extraQueryParameters: {
            campaignId: window.location.hostname
        },
        authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority,
        account: accounts[0]
    };

    useEffect(() => {
        if (accounts.length === 0) {
            navigate('/?unauthenticated=true');
        }

        if (count === 0) {
            setLoading(true);
            count++;
        }
    }, []);

    useEffect(() => {
        if (!tokenExpiry) {
            return;
        }

        const timer = setTimeout(() => {
            navigate('/?unauthenticated=true');
        }, tokenExpiry.getTime() - Date.now());
            
        return () => {
            clearTimeout(timer);
        };
    }, [tokenExpiry]);

    useEffect(() => {
        if (accounts.length > 0 && accountCount === 0) {
            getFlow();
            accountCount++;
        }
    }, [inProgress, accounts]);

    const getFlow: () => void = async () => {
        setLoading(true);
        const token = await authResult(request, instance);
        if (!token) {
            navigate('/?unauthenticated=true');
        } else {
            setToken(token.accessToken);
            setTokenExpiry(token.expiresOn);

            if (loading || !params.linkUid) return;
    
            const getFlowRes: Response = await get(
                apiConfig.getFlow(params.linkUid),
                token.accessToken
            );
    
            if (!getFlowRes.ok) {
                errorHandler();
                return;
            }
    
            const getFlowResJson = await getFlowRes.json();
    
            setCustomerUid(getFlowResJson.customerUid);
            setPartnerUid(getFlowResJson.partner.id);
    
            const mappedData: Response = await get(
                apiConfig.getConnectorTypes(getFlowResJson.customerUid, getFlowResJson.partner.id),
                token.accessToken
            );
    
            if (!mappedData.ok) {
                errorHandler();
                return;
            }
    
            const mappedDataJson = await mappedData.json();
            setConnectorTypes(mappedDataJson);
            setLoading(false);
        }
    };

    const errorHandler: () => void = () => {
        setError(true);
        setLoading(false);
    };

    const connectCompleteHandler: () => void = () => setConnectionComplete(true);

    return loading && !processing ? (
        <LoaderFullPage />
    ) : (
        <CustomerContainer>
            <CustomerTopContainer>
                {error && <Alert severity="error">Something went wrong. Please contact us.</Alert>}
                {connectionComplete && customerUid && token && (
                    <Typography tag="h1" fontWeight="bold">
                        You have successfully connected the following accounting system<br></br>and
                        you can now close this browser tab.
                    </Typography>
                )}
                {!connectionComplete && !processing && (
                    <>
                        <Typography tag="h1" fontWeight="bold">
                            Connect to your accounting system
                        </Typography>
                    </>
                )}
                {!error && customerUid && token && (
                    <ERPSelectionTiles
                        setup={
                            new G2FSetup(
                                token,
                                `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                                customerUid,
                                `${window.location.origin}/app/connection?customerUid=${customerUid}&partnerUid=${partnerUid}`,
                                '',
                                connectorTypes,
                                partnerUid
                            )
                        }
                        connectionCompleteCallback={connectCompleteHandler}
                        processingConnectionCallback={(val) => setProcessing(val)}
                    />
                )}
            </CustomerTopContainer>
            {!error && customerUid && token && (
                <CustomerBottomContainer>
                    <Typography tag="subheading1">
                        Your accounting system not here? Please tell us what you use and we'll get
                        back to you when it's available.
                    </Typography>
                    <CustomerReportContainer>
                        <TextField
                            sx={{ ...inputSxStyles, flex: 1 }}
                            id="finecta-report-system"
                            label="Your accounting system"
                            variant="outlined"
                            fullWidth
                            value={accountingSystem}
                            onChange={(e) => setAccountingSystem(e.target.value)}
                        />
                        <CustomerButtonContainer>
                            <a
                                href={
                                    Boolean(accountingSystem)
                                        ? `mailto:help@finecta.com?subject=Please add ${accountingSystem}`
                                        : ''
                                }
                            >
                                <PrimaryButton
                                    disabled={!accountingSystem}
                                    width="fit-content"
                                    clickHandler={(e) => {
                                        if (!Boolean(accountingSystem)) e.preventDefault();
                                    }}
                                >
                                    Notify Us
                                </PrimaryButton>
                            </a>
                        </CustomerButtonContainer>
                    </CustomerReportContainer>
                </CustomerBottomContainer>
            )}
        </CustomerContainer>
    );
};

export default Customer;
