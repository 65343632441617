import { _cookie1, _cookie2 } from './constants';

export function cookieExists(name: string) {
    return document.cookie.split(';').some((c) => {
        return c.trim().startsWith(name + '=');
    });
}

export function stateCookieExists(stateName: string) {
    var state = JSON.parse(atob(stateName));
    return document.cookie.split(';').some((item) => item.trim().startsWith(`${state.name}=`));
}

export function connectionCookieData() {
    const name = document.cookie.match('(^|;) ?' + 'g2fErpConnectionSystem' + '=([^;]*)(;|$)');
    const isConnected = document.cookie.match(
        '(^|;) ?' + 'g2fErpConnectionConnected' + '=([^;]*)(;|$)'
    );
    return [name ? name[2] : null, isConnected ? isConnected[2] : null];
}

export function getStateCookie(stateName: string) {
    var state = JSON.parse(atob(stateName));
    var v = document.cookie.match('(^|;) ?' + state.name + '=([^;]*)(;|$)');
    return v ? v[2] : null;
}
