import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const SignInContainer = styled(G2FSSContainer)``;

export const SignInHeadingContainer = styled(G2FSSContainer)`
    margin-bottom: 24px;
`;

export const SignInRow = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
`;

export const SignInMailSpan = styled.span`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
`;
