/* eslint-disable eqeqeq */
import nimblaArrow from 'assets/nimbla-arrow.png';
import nimblaIcon from 'assets/nimbla-icon.png';
import nimblaTCTLogo from 'assets/nimbla-tct-logo.png';
import { ChangeEvent, Component } from 'react';
import { Card } from 'reactstrap';

import { Checkbox } from '@mui/material';
import { apiConfig, get, put } from 'api/apiConfig';
import { themeColors } from 'assets/theme/style';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import Typography from 'components/typography';
import config from 'config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ERPSelectionTiles from 'components/ERPSelectionTiles';
import G2FSetup from 'lib/setup';
import {
    NimblaWelcomeBodyCheckboxWrapper,
    NimblaWelcomeBodyContainer,
    NimblaWelcomeConnectedContainer,
    NimblaWelcomeContainer,
    NimblaWelcomeTopRow,
    NimblaFloat,
    ButtonStyled
} from './styled';
import jwt_decode from "jwt-decode";

export class NimblaWelcome extends Component {
    constructor(props: any) {
        super(props);

        var campaignLogo = config.campaigns.default.logo;

        (this.state as any) = {
            isAgreedToTermsAndConditions: false
        };

        Object.keys(config.campaigns).forEach(function (key) {
            var campaign_hostname = config.campaigns[key].hostname.toLowerCase();
            var current_hostname = window.location.hostname.toLowerCase();
            var logo = config.campaigns[key].logo;

            if (campaign_hostname === current_hostname) {
                campaignLogo = logo;
            }
        });

        const urlParams = new URLSearchParams(document?.location?.search);
        (this.state as any) = {
            loading: true,
            pageSpinner: 'visible',
            connectorConfig: config.connectors['xero'],
            hasCode: urlParams.get('code') != undefined,
            connectorValid: urlParams.get('connectorValid'),
            showCustomerButton: false,
            step: 0,
            logo: campaignLogo,
            accessToken: '',
            showConnector: false,
            error: ''
        };
        if ((this.state as any).connectorValid == 'true') (this.state as any).step = 1;
        this.onConnectorAuthStatusChange = this.onConnectorAuthStatusChange.bind(this);
        this.getNimblaCompany = this.getNimblaCompany.bind(this);
        this.redeemInvite = this.redeemInvite.bind(this);
        this.Step1 = this.Step1.bind(this);
        this.Step2 = this.Step2.bind(this);
        this.Step3 = this.Step3.bind(this);
        this.Step4 = this.Step4.bind(this);
    }

    async componentDidMount() {
        this.isLoggedIn();
    }

    getScopes(accessToken: string): string[] {
        const token = jwt_decode<any>(accessToken);
        const { idp_access_token } = token;
        
        if (idp_access_token) {
            const idpToken = jwt_decode<any>(idp_access_token);
            const { scope } = idpToken;

            return scope.split(' ');
        }
        return [];
    }

    containsScopes = (scopes: string[], requiredScopes: string[]) => requiredScopes.every(s => scopes.includes(s));

    isLoggedIn() {
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        fetch(
            `${window.spaSettings.g2fApiUrl}/${apiConfig.extensionIsLoggedIn}?domain_hint=nimbla`,
            {
                redirect: 'manual',
                credentials: 'include',
                headers
            }
        )
            .then(async (response) => {
                if (response.type === 'opaqueredirect') {
                    window.location.href = `${window.spaSettings.g2fApiUrl}/api/extension/login?domain_hint=nimbla&redirectUri=${window.origin}/nimblawelcome`;
                } else if (response.ok) {
                    return response.json();
                }
                return;
            })
            .then((responseJson) => {
                let scopes = this.getScopes(responseJson.accessToken);
                let containsReport = this.containsScopes(scopes, ['read:report:pdf']);
                let containsDebtor = this.containsScopes(scopes, ['read:debtor:profile']);
                this.setState(
                    {
                        accessToken: responseJson.accessToken,
                        identityProvider: responseJson.identityProvider,
                        showConnector: !(containsReport && !containsDebtor)
                    },
                    this.redeemInvite
                );
            });
    }

    async redeemInvite() {
        const getInviteRes = await get(apiConfig.partnerInvite(window.spaSettings.nimblaPartnerId), (this.state as any).accessToken);

        if (!getInviteRes.ok) {
            this.setState({
                loading: false,
                error: 'Failed to redeem invite'
            });
        }

        const getInviteResJson = await getInviteRes.json();

        if (getInviteResJson.status === 'Redeemed') {
            this.getNimblaCompany();
            return;
        }

        if (getInviteResJson.status !== 'Pending') {
            this.setState({
                loading: false,
                error: 'Invite has been revoked by Nimbla'
            });
            return;
        }

        const redeemInviteRes = await put(
            apiConfig.partnerInvite(window.spaSettings.nimblaPartnerId),
            { status: 'Redeemed' },
            (this.state as any).accessToken
        );

        if (!redeemInviteRes.ok) {
            this.setState({
                loading: false,
                error: 'Failed to redeem invite'
            });
        }

        this.getNimblaCompany();
    };

    getConnectorTypes() {
        this.fetchWithToken(apiConfig.getConnectorTypes((this.state as any).customer.id, window.spaSettings.nimblaPartnerId), 'GET')
        .then((responseJson) => {
            if (responseJson) {
                this.setState(
                    {
                        connectorTypes: responseJson,
                        loading: false,
                        pageSpinner: 'hidden'
                    }
                );
            }
        });
    }

    getCompany(callback: any) {
        this.fetchWithToken(apiConfig.customersSummary(true), 'GET').then((responseJson) => {
            if (responseJson) {
                this.setState(
                    {
                        customer: responseJson.customers[0]
                    },
                    callback
                );
            }
        });
    }

    createIfBlankCompany(name: any, registrationNumber: any, externalCustomerId: any) {
        if (!(this.state as any).customer) {
            this.fetchWithToken(apiConfig.flows, 'POST', {
                name: name,
                registrationNumber: registrationNumber,
                connectorType: 'xero',
                connectorSchedule: '0 0 0 * * *',
                partnerId: window.spaSettings.nimblaPartnerId,
                originator: 'Hipster',
                mainInstance: window.location.hostname,
                extensions: [
                    {
                        key: 'nimblaCompanyId',
                        value: externalCustomerId
                    }
                ]
            }).then(() => {
                this.getCompany(this.getConnectorTypes);
            });
        } else {
            this.getConnectorTypes();
        }
    }

    async getNimblaCompany() {
        await this.fetchWithToken(apiConfig.myCompany, 'GET').then(async (responseJson) => {
            if (responseJson) 
            {
                this.getCompany(() => {
                    this.createIfBlankCompany(
                        responseJson.name,
                        responseJson.registrationNumber,
                        responseJson.id
                    );
                });
            }
        });
    }

    async onConnectorAuthStatusChange(state: any) {
        if (state.validRequest) {
            await this.getCompany(() => {
                this.startImport(() => {
                    window.location.href = `${window.location.origin}/nimblawelcome?connectorValid=${state.validRequest}`;
                });
            });
        }
    }

    async startImport(callback: any) {
        await this.fetchWithToken(
            apiConfig.importInvoices((this.state as any).customer.connector.connectorUid),
            'GET'
        );
        callback();
    }

    Step1() {
        this.setState({
            step: 1
        });
    }
    Step2() {
        this.setState({
            step: 2
        });
    }
    Step3() {
        this.setState({
            step: 3
        });
    }
    Step4() {
        this.setState({
            step: 4
        });
    }

    fetchWithToken(url: any, method: any, body?: any) {
        const headers = new Headers();

        headers.append('Authorization', `Bearer ${(this.state as any).accessToken}`);
        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        return fetch(`${window.spaSettings.g2fApiUrl}/${url}`, {
            method: method,
            body: body && JSON.stringify(body),
            redirect: 'manual',
            headers: headers
        }).then((response): any => {
            if (response.type === 'opaqueredirect') {
                window.location.href = `${response.url}&redirectUri=${window.origin}/nimblawelcome`;
            } else if (response.ok) {
                return response.json();
            } else if (response.status == 403) {
                toast.error('You do not have permissions to perform this action.');
                
                this.setState({
                    error: 'You do not have permissions to perform this action.',
                    loading: false
                });
                return null;
            } else {
                toast.error('An unexpected error occurred, please try again later.');
                
                this.setState({
                    error: 'An unexpected error occurred, please try again later.',
                    loading: false
                });
                return null;
            }
        });
    }

    handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>) => {
        const { checked } = event.target;
        this.setState({ isAgreedToTermsAndConditions: checked });
    };

    render() {
        return (
            <NimblaWelcomeContainer>
                <NimblaFloat>
                    <ButtonStyled 
                        onClick={(e) => {
                            e.preventDefault();
                            window.location.href = `${window.spaSettings.g2fApiUrl}/${apiConfig.extensionLogout}?domain_hint=nimbla&redirectUri=${window.origin}/nimblawelcome`;
                        }}>Logout</ButtonStyled>
                </NimblaFloat>
                <Card>
                    <NimblaWelcomeTopRow>
                        <img src={nimblaTCTLogo} alt="nimbla tct logo" />
                    </NimblaWelcomeTopRow>
                    {(this.state as any).loading && <LoaderInPage size={10} />}

                    {!(this.state as any).loading &&
                        (this.state as any).identityProvider != 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">Welcome</Typography>
                                <Typography tag="body1large">
                                    Apologies, this is only available to Nimbla customers.
                                </Typography>
                                <a href="https://www.nimbla.com" target="_blank" rel="noreferrer">
                                    https://www.nimbla.com
                                </a>
                            </NimblaWelcomeBodyContainer>
                        )}
                    {!(this.state as any).loading &&
                        (this.state as any).error &&
                        !(this.state as any).customer &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">{(this.state as any).error}</Typography>
                            </NimblaWelcomeBodyContainer>
                        )}
                    {!(this.state as any).loading &&
                        !(this.state as any).error &&
                        !(this.state as any).customer &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">Please wait, loading</Typography>
                            </NimblaWelcomeBodyContainer>
                        )}
                    {!(this.state as any).loading &&
                        !(this.state as any).error &&
                        (this.state as any).customer &&
                        !(this.state as any).hasCode &&
                        (this.state as any).showConnector == true &&
                        (this.state as any).connectorValid !== 'true' &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">You're in!</Typography>
                                <Typography tag="body1large">
                                    Now select your accounting system and follow the instructions to
                                    grant Nimbla access.
                                </Typography>
                                <Typography tag="body1large">
                                    We'll then analyse the last 2 years of data so we can predict
                                    the cover you will need for the next 12 months.
                                </Typography>
                                <NimblaWelcomeBodyCheckboxWrapper>
                                    <Checkbox
                                        checked={(this.state as any).isAgreedToTermsAndConditions}
                                        onChange={this.handleCheckboxChange}
                                        sx={{
                                            padding: '0',
                                            color: themeColors.secondary,
                                            '&.Mui-checked': {
                                                color: themeColors.primary
                                            },
                                            '&:hover': {
                                                backgroundColor: `${themeColors.primary}20`
                                            }
                                        }}
                                    />
                                    <Typography tag="h4">
                                        By connecting your account system you agree to abide by our{' '}
                                        <span
                                            style={{
                                                color: themeColors.primary,
                                                cursor: 'pointer',
                                                zIndex: 100
                                            }}
                                            onClick={(e) => {
                                                e.preventDefault();
                                                window.open(
                                                    `${window.location.origin}/termsandconditions`,
                                                    '_BLANK'
                                                );
                                            }}
                                        >
                                            Terms and Conditions.
                                        </span>
                                    </Typography>
                                </NimblaWelcomeBodyCheckboxWrapper>
                                {(this.state as any).isAgreedToTermsAndConditions && (
                                <ERPSelectionTiles
                                    setup={
                                        new G2FSetup(
                                            (this.state as any).accessToken,
                                            `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                                            (this.state as any).customer.id,
                                            `${window.location.href}?partnerUid=${window.spaSettings.nimblaPartnerId}&connectorValid=true`,
                                            '',
                                            (this.state as any).connectorTypes,
                                            window.spaSettings.nimblaPartnerId
                                        )
                                    }
                                />
                                )}
                            </NimblaWelcomeBodyContainer>
                        )}
                    {!(this.state as any).loading &&
                        !(this.state as any).error &&
                        (this.state as any).customer &&
                        !(this.state as any).hasCode &&
                        (this.state as any).showConnector == true &&
                        (this.state as any).connectorValid == 'false' &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">
                                    Could not configure your connector, please try again later.
                                </Typography>
                            </NimblaWelcomeBodyContainer>
                        )}
                    {!(this.state as any).loading &&
                        !(this.state as any).error &&
                        (this.state as any).customer &&
                        !(this.state as any).hasCode &&
                        (this.state as any).showConnector == true &&
                        (this.state as any).connectorValid == 'true' &&
                        (this.state as any).step !== 1 &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <Typography tag="h4">
                                    Your connector has been successfully configured, please wait one
                                    moment...
                                </Typography>
                                <LoaderInPage size={10} />
                            </NimblaWelcomeBodyContainer>
                        )}
                    {(this.state as any).step == 1 &&
                        !(this.state as any).error &&
                        (this.state as any).customer &&
                        (this.state as any).showConnector == true &&
                        (this.state as any).identityProvider == 'nimbla' && (
                            <NimblaWelcomeBodyContainer>
                                <NimblaWelcomeConnectedContainer>
                                
                                    <ERPSelectionTiles
                                        setup={
                                            new G2FSetup(
                                                (this.state as any).accessToken,
                                                `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                                                (this.state as any).customer.id,
                                                `${window.location.href}?partnerUid=${window.spaSettings.nimblaPartnerId}&connectorValid=true`,
                                                '',
                                                (this.state as any).customer.connectorTypes,
                                                window.spaSettings.nimblaPartnerId
                                            )
                                        }
                                    />
                                    <img src={nimblaArrow} alt="nimbla arrow" />
                                    <img src={nimblaIcon} alt="nimbla icon" />
                                </NimblaWelcomeConnectedContainer>
                                <Typography tag="h4">Success! You're connected.</Typography>
                                <Typography tag="body1large">
                                    We're fetching your data from Xero now. This could take between
                                    15 minutes and 4 hours, depending on how many invoices you have
                                    issued in the past 2 years.
                                </Typography>
                                <Typography tag="body1large">
                                    Once we have enough data, we'll email you instructions on
                                    downloading the browser extension.
                                </Typography>
                                <Typography tag="body1large">
                                    You may now close this tab.
                                </Typography>
                            </NimblaWelcomeBodyContainer>
                        )}
                        {!(this.state as any).loading &&
                            !(this.state as any).error &&
                            (this.state as any).showConnector == false &&
                            (this.state as any).identityProvider == 'nimbla' && (
                                <NimblaWelcomeBodyContainer>
                                    <Typography tag="h4">You're in!</Typography>
                                    <Typography tag="body1large">
                                        To begin downloading your reports you will need to download our extension.
                                    </Typography>
                                    <ButtonStyled 
                                        onClick={(e) => {
                                            e.preventDefault();
                                            window.location.href = `https://chrome.google.com/webstore/detail/gateway-2-finance/iggnndglegeckcbgcklmmkgnhaladaja`;
                                        }}>Download Now</ButtonStyled>
                                </NimblaWelcomeBodyContainer>
                            )}
                </Card>

                <ToastContainer
                    position="top-right"
                    autoClose={3000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                />
            </NimblaWelcomeContainer>
        );
    }
}
