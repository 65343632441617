import { FC, useState } from 'react';
import EDIConfigurer from './EDIConfigurer';
import EDIUploadConfigurer from './EDIUploadConfigurer';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import { AccordionStyled, ConfigurationsContainer } from './styled';

interface IEdiAccordion {
    customerUID: string;
    partnerTenantId: string;
    token: string;
    redirectUrl: string;
}

const Configurations: FC<IEdiAccordion> = ({ customerUID, token, partnerTenantId, redirectUrl }) => {
    const [expanded, setExpanded] = useState<'DragAndDrop' | 'SFTP'>('DragAndDrop');
    const handleExpandedChange =
        (panel: string) => (_event: React.SyntheticEvent, isExpanded: boolean) => {
            if(panel === 'DragAndDrop') {
                setExpanded(isExpanded ? 'DragAndDrop' : 'SFTP');
            } else {
                setExpanded(isExpanded ? 'SFTP' : 'DragAndDrop');
            }
        };

    return (
        <ConfigurationsContainer>
            <AccordionStyled
                disableGutters
                expanded={expanded === 'DragAndDrop'}
                onChange={handleExpandedChange('DragAndDrop')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}

                >
                    Drag & Drop
                </AccordionSummary>
                <AccordionDetails>
                    <EDIUploadConfigurer customerUID={customerUID} token={token} />
                </AccordionDetails>
            </AccordionStyled>
            <AccordionStyled
                disableGutters
                expanded={expanded === 'SFTP'}
                onChange={handleExpandedChange('SFTP')}
            >
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                >
                    SFTP Configuration
                </AccordionSummary>
                <AccordionDetails>
                    <EDIConfigurer customerUID={customerUID} token={token} partnerTenantId={partnerTenantId} redirectUrl={redirectUrl} />
                </AccordionDetails>
            </AccordionStyled>
        </ConfigurationsContainer>
    );
};

export default Configurations;
