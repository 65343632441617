import { themeColors } from 'assets/theme/style';
import { fontWeights } from 'assets/theme/typography';
import styled from 'styled-components';

export const Link = styled.span`
    color: ${themeColors.accent};
    font-weight: ${fontWeights.bold};
    cursor: pointer;
    &:hover {
        color: ${themeColors.primary};
    }
    &:visited {
        color: ${themeColors.accent};
    }
`;
