/* eslint-disable react-hooks/exhaustive-deps */
import {
    AuthenticationResult,
    EventType,
    IPublicClientApplication,
    RedirectRequest
} from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { SilentRequest } from '@azure/msal-browser';
import { Alert, TextField } from '@mui/material';
import { apiConfig, b2cPolicies, get, put } from 'api/apiConfig';
import { inputSxStyles, themeColors } from 'assets/theme/style';
import LoaderFullPage from 'components/LoaderFullPage';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { PrimaryButton } from 'components/common/buttons';
import { Link } from 'components/link/styled';
import Typography from 'components/typography';
import { _EMAIL_REGEX } from 'lib/validation/validation';
import { FC, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { SignInContainer, SignInHeadingContainer, SignInMailSpan, SignInRow } from './styled';

const SignIn: FC = () => {
    const { instance, accounts, inProgress }: IMsalContext = useMsal();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(true);
    const [email, setEmail] = useState<string>('');
    const [emailValid, setEmailValid] = useState<boolean>(false);
    const [submissionMessage, setSubmissionMessage] = useState<string>('');
    const [submissionMessageLink, setSubmissionMessageLink] = useState<string>('');
    const [submissionIcon, setSubmissionIcon] = useState<JSX.Element | null>(null);
    const [submitting, setSubmitting] = useState<boolean>(false);
    const [urlState, setUrlState] = useState<string>('');
    const tokenParams = searchParams.get('token');
    const unauthenticatedParams = searchParams.get('unauthenticated');
    const [isError, setIsError] = useState<boolean>(false);
    const [customers, setCustomers] = useState<any[]>([]);

    useEffect(() => {
        const hash = window.location.hash;
        if (hash.includes('state=')) {
            const hashValue = hash.split('=')[1];
            setUrlState(hashValue);
        }
        instance.addEventCallback((event: any) => {
            // set active account after redirect
            if (event.eventType === EventType.LOGIN_FAILURE) {
                setIsError(true);
            }
        });
    }, []);

    useEffect(() => {
        if (isError || !tokenParams) {
            setLoading(false);
        } else {
            if (inProgress === 'none') {
                accounts.length > 0 ? determineConnectors() : initSigninSequence(tokenParams);
            }
        }
    }, [accounts, inProgress, customers]);

    const determineConnectors: () => void = async () => {
        const loadConnectorsRes = await loadConnectors();

        if (loadConnectorsRes.length === 1) {
            navigate(`/app/customer/${loadConnectorsRes[0].id}`);
        } else {
            navigate('/app/connections');
        }
    };

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ],
        extraQueryParameters: {
            campaignId: window.location.hostname
        },
        authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority,
        account: accounts[0]
    };

    const loadConnectors: () => Promise<any[]> = async () => {
        const instanceResponse: AuthenticationResult = await instance.acquireTokenSilent(request);
        const getFlowsRes: Response = await get(apiConfig.flows, instanceResponse.accessToken);

        if (!getFlowsRes.ok) return;

        const getFlowsResJson = await getFlowsRes.json();
        setCustomers(getFlowsResJson.linkedFlows);
        return getFlowsResJson.linkedFlows;
    };

    const initSigninSequence: (token: string) => void = async (token) => {
        const request: RedirectRequest = {
            scopes: [
                `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
            ],
            extraQueryParameters: {
                id_token_hint: token,
                campaignId: window.location.hostname
            },
            prompt: 'login',
            authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority
        };
        await (instance as IPublicClientApplication).loginRedirect(request);
    };

    const createClickHandler: () => void = async () => {
        setSubmitting(true);
        const headers = new Headers();

        headers.append('Content-Type', 'application/json');
        headers.append('Accept', 'application/json');

        const options = {
            email,
            host: window.location.host,
            redirectUrl: `${window.location.protocol}//${window.location.host}`
        };

        const res: Response = await put(`${apiConfig.registerPasswordless}`, options);

        if (!res.ok) {
            setSubmissionMessage(
                'We encountered a problem generating the email. Please contact us'
            );
            setSubmissionMessageLink(
                'mailto:help@finecta.com?subject=Cannot generate passwordless link'
            );
            setSubmissionIcon(<ErrorOutlineIcon style={{ color: themeColors.error }} />);
        } else {
            setSubmissionMessage(
                'Successfully generated email. Please check your inbox. This may take a few minutes to arrive.'
            );
            setSubmissionIcon(<CheckCircleOutlineIcon style={{ color: themeColors.success }} />);
        }

        setSubmitting(false);
    };

    const isEmailValid: (email: string) => boolean = (email) => _EMAIL_REGEX.test(email.trim());

    const renderSignInView: () => JSX.Element = () => (
        <SignInContainer>
            <SignInHeadingContainer>
                <Typography tag="h1" fontWeight="regular">
                    Sign in to G2F
                </Typography>
                <Typography tag="subheading1">
                    Enter your email and we will send a link that can be used to manage your
                    connectors
                </Typography>
            </SignInHeadingContainer>
            <div>
                {
                    unauthenticatedParams == 'true' &&
                    <Alert severity="warning">You are no longer authenticated, please log in again to continue</Alert>
                }
            </div>
            <SignInRow>
                <TextField
                    style={{
                        display: 'flex'
                    }}
                    fullWidth
                    sx={inputSxStyles}
                    id="email"
                    label="Email address"
                    variant="outlined"
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                        setEmail(event.target.value);
                        setEmailValid(isEmailValid(event.target.value));
                    }}
                />
                <PrimaryButton
                    clickHandler={createClickHandler}
                    disabled={!emailValid || submitting}
                    width="fit-content"
                    height="54px"
                >
                    {submitting ? <LoaderInPage /> : 'Send'}
                </PrimaryButton>
            </SignInRow>
            {submissionMessage && (
                <Typography tag="body1" textColor="accent">
                    <SignInMailSpan>
                        {submissionIcon}
                        <span>
                            {submissionMessage}{' '}
                            {submissionMessageLink && (
                                <Link
                                    onClick={() =>
                                        window.open(
                                            'mailto:help@finecta.com?subject=Can not generate passwordless email'
                                        )
                                    }
                                >
                                    here
                                </Link>
                            )}
                        </span>
                    </SignInMailSpan>
                </Typography>
            )}
        </SignInContainer>
    );

    const renderErrorView: () => JSX.Element = () => (
        <SignInContainer>
            <SignInHeadingContainer>
                <Typography tag="p">
                    Oops! This link does not work. It may have been used before or it has expired.
                </Typography>
                <Typography tag="p">Please contact your partner for a new invitation. </Typography>
                <Typography tag="p">
                    If trouble persists, contact us on{' '}
                    <Link onClick={() => window.open('mailto:support@finecta.com')}>
                        support@finecta.com
                    </Link>
                </Typography>
            </SignInHeadingContainer>
        </SignInContainer>
    );

    const renderView: () => JSX.Element = () => {
        if (!loading && tokenParams === null && urlState === '' && !isError)
            return renderSignInView();

        if (!loading && tokenParams && isError) return renderErrorView();

        return <LoaderFullPage />;
    };

    return renderView();
};

export default SignIn;
