export const apiConfig = {
    register: () => `api/register`,
    registerByInvite: (inviteUid: string) => `api/register/${inviteUid}`,
    registerByPartnerInvite: (inviteUid: string) => `api/register/partner/${inviteUid}`,
    registerPasswordless: `api/register/passwordless`,
    acceptInvite: (inviteUid: string) => `api/invites/${inviteUid}/accept`,
    customers: 'customers',
    partnerInvite: (partnerUid: string) => `api/partner/${partnerUid}/invite`,
    flows: `api/flows`,
    getFlow: (flowUid: string) => `api/flows/${flowUid}`,
    addConnector: (customerUid: string) => `api/customers/${customerUid}/connector`,
    addConnectorWithType: (customerUid: string, connectorType: string) =>
        `api/customers/${customerUid}/connector/${connectorType}`,
    getConnector: (customerUid: string) => `api/customers/${customerUid}/connector`,
    getConnectorTypes: (customerUid: string, partnerTenantId: string) =>
        `api/customers/${customerUid}/connector/${partnerTenantId}/types`,
    updateConnector: (customerUid: string) => `api/customers/${customerUid}/connector`,
    deleteConnector: (customerUid: string) => `api/customers/${customerUid}/connector`,
    getTenants: (customerUid: string) => `api/customers/${customerUid}/connector/tenants`,
    updateTenant: (customerUid: string) => `api/customers/${customerUid}/connector/tenants`,
    updateSchedule: (customerUid: string) => `api/customers/${customerUid}/connector/schedule`,
    postInvoices: (customerUid: string) => `api/customers/${customerUid}/import/invoices`,
    triggerInvoiceImport: (customerUid: string) => `api/customers/${customerUid}/import/invoices`,
    getExternalCustomers: (odataFilter: string) => `odata/externalcustomers?${odataFilter}`,
    getImportStatus: (customerUid: string, instanceId: string) =>
        `api/customers/${customerUid}/import/runs/${instanceId}`,
    updateExternalCustomerExtension: (customerUid: string, externalCustomerRef: string) =>
        `/api/externalcustomers/${customerUid}/${externalCustomerRef}/extensions`,
    extensionIsLoggedIn: `api/extension/isloggedin`,
    extensionLogout: `api/extension/logout`,
    customersSummary: (includeMemberGroups: boolean) =>
        `customers?includeMemberGroups=${includeMemberGroups}`,
    myCompany: `api/insurance/mycompany`,
    importInvoices: (connectorUid: string) => `api/import/importInvoices/${connectorUid}`,
    uploadCustomerFile: (customerUid: string) =>
        `api/customers/${customerUid}/connector/file/upload`,
    companiesHouseSearch: (search: string) => `companieshouse/search?query=${search}`,
    getHeadlessConnector: (customerUid: string, connectorUid: string) => `api/headless/customers/${customerUid}/connector/${connectorUid}`,
    getHeadlessConnectorTenants: (customerUid: string, connectorUid: string) => `api/headless/customers/${customerUid}/connector/${connectorUid}/tenants`
};

export async function get(endpoint: string, authenticationParameters: string) {
    return await send(endpoint, 'GET', null, authenticationParameters);
}

export async function post(endpoint: string, body: any, authenticationParameters: string) {
    return await send(endpoint, 'POST', body, authenticationParameters);
}

export async function postFile(endpoint: string, body: any, authenticationParameters: string) {
    return await sendFile(endpoint, 'POST', body, authenticationParameters);
}

export async function put(endpoint: string, body: any, authenticationParameters?: string) {
    return await send(endpoint, 'PUT', body, authenticationParameters);
}

export async function patch(endpoint: string, body: any, authenticationParameters: string) {
    return await send(endpoint, 'PATCH', body, authenticationParameters);
}

export async function del(endpoint: string, authenticationParameters: string) {
    return await send(endpoint, 'DELETE', null, authenticationParameters);
}

async function send(
    endpoint: string,
    method: string,
    body: any | null,
    authenticationParameters?: string
) {
    const headers = new Headers();

    if (authenticationParameters)
        headers.append('Authorization', `Bearer ${authenticationParameters}`);
    headers.append('Content-Type', 'application/json');
    headers.append('Accept', 'application/json');

    const options: RequestInit = {
        method,
        body: body && JSON.stringify(body),
        headers
    };

    return await fetch(`${window.spaSettings.g2fUrl}/${endpoint}`, options);
}

async function sendFile(
    endpoint: string,
    method: string,
    body: null,
    authenticationParameters: string
) {
    const headers = new Headers();
    headers.append('Authorization', `Bearer ${authenticationParameters}`);
    const options: RequestInit = {
        method,
        body,
        headers
    };

    return await fetch(`${window.spaSettings.g2fUrl}/${endpoint}`, options);
}

export const b2cPolicies = {
    names: {
        inviteRedeem: 'B2C_1A_INVITEREDEEM',
        forgotPassword: 'B2C_1A_PASSWORDRESET'
    },
    authorities: {
        inviteRedeemPasswordless: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_INVITEREDEEM_PASSWORDLESS`
        },
        signUpSignIn: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_SIGNUP_SIGNIN`
        },
        forgotPassword: {
            authority: `https://${window.spaSettings.b2cDomain}/${window.spaSettings.tenantId}/B2C_1A_PASSWORDRESET`
        }
    },
    authorityDomain: `https://${window.spaSettings.b2cDomain}`
};

const nimblaApiParameters = {
    base: `https://${window.spaSettings.instanceName}.onmicrosoft.com/${window.spaSettings.apiClientId}`
};

export const defaultAuthenticationParameters = {
    baseUrl: window.spaSettings.g2fApiUrl,
    scopes: [
        `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/Api`,
        `${nimblaApiParameters.base}/CorporatePortal`
    ],
    state: btoa(
        JSON.stringify({
            redirectUrl: window.location.origin,
            campaignId: window.location.hostname
        })
    ),
    extraQueryParameters: {
        campaignId: window.location.hostname
    }
};

export const loginRequest = {
    scopes: []
};

export const authenticationParameters = defaultAuthenticationParameters;
