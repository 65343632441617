import styled from 'styled-components';

export const FullPageLoaderCenteringContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100vh;
    flex: 1;
`;

export const FullPageLoaderContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 16px;
    top: 0;
    bottom: 0;
    margin: auto 0;
`;

export const FullPageLoaderHeading = styled.h2``;

export const FullPageLoaderText = styled.p``;
