import { TextField } from '@mui/material';
import { apiConfig, post, put } from 'api/apiConfig';
import { inputSxStyles } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { FC, useState } from 'react';

interface IFIDESConfigurer {
    customerUID: string;
    partnerTenantId: string;
    token: string;
    redirectUrl: string;
}

const FIDESConfigurer: FC<IFIDESConfigurer> = ({
    customerUID,
    partnerTenantId,
    token,
    redirectUrl
}) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [saved, setSaved] = useState<boolean>(false);
    const [sftpHostname, setSftpHostname] = useState<string>('');
    const [sftpUsername, setSftpUsername] = useState<string>('');
    const [path, setPath] = useState<string>('');
    const [privateKey, setPrivateKey] = useState<string>('');
    const [passphrase, setPassphrase] = useState<string>('');
    const [addBankPositionUrl, setAddBankPositionUrl] = useState<string>('');

    const saveConnector: () => Promise<void> = async () => {
        setLoading(true);
        const addConnectorWithTypeResponse = await post(
            apiConfig.addConnectorWithType(customerUID, 'fides'),
            {
                customerUid: customerUID,
                connectorType: 'fides',
                partnerTenantId,
                configuration: {
                    hostname: sftpHostname,
                    username: sftpUsername,
                    path: path,
                    addBankPositionUrl: addBankPositionUrl,
                    privateKey: privateKey,
                    passphrase: passphrase
                }
            },
            token
        );

        if (!addConnectorWithTypeResponse.ok) throw Error('Can not add FIDES Connector Type');

        saveDefaultConnectorSchedule();
        setLoading(false);
        setSaved(true);
        window.location.href = redirectUrl + '&isComplete=true'; 
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(apiConfig.updateSchedule(customerUID), {
            schedule: '0 0 0 * * *'
        }, token);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: loading ? '100%' : 'fit-content'
            }}
        >
            {!loading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'row',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        gap: '16px'
                    }}
                >
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={sftpHostname}
                        sx={inputSxStyles}
                        id="hostname"
                        label="SFTP Hostname"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSftpHostname(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={sftpUsername}
                        sx={inputSxStyles}
                        id="username"
                        label="SFTP Username"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setSftpUsername(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={path}
                        sx={inputSxStyles}
                        id="path"
                        label="Path"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPath(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={addBankPositionUrl}
                        sx={inputSxStyles}
                        id="bankpositionurl"
                        label="Add Bank Position Url"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setAddBankPositionUrl(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={privateKey}
                        sx={inputSxStyles}
                        id="privatekey"
                        label="Private Key"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPrivateKey(event.target.value);
                        }}
                    />
                    <TextField
                        style={{
                            display: 'flex'
                        }}
                        fullWidth
                        value={passphrase}
                        sx={inputSxStyles}
                        id="passphrase"
                        label="Pass Phrase"
                        variant="outlined"
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            setPassphrase(event.target.value);
                        }}
                    />
                    <PrimaryButton
                        disabled={loading || saved}
                        clickHandler={saveConnector}
                        width="fit-content"
                    >
                        {loading ? <LoaderInPage /> : saved ? 'Saved!' : 'Save'}
                    </PrimaryButton>
                </div>
            )}
        </div>
    );
};

export default FIDESConfigurer;
