/* eslint-disable react-hooks/exhaustive-deps */
import { apiConfig, get } from 'api/apiConfig';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import LoaderFullPage from 'components/LoaderFullPage';
import connectorsConfig from '../../config/config';

const HeadlessConnectStart: FC = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        getConnector();
    }, []);

    const getConnector: () => void = async () => {
        setLoading(true);
        let redirectUrl = searchParams.get('redirectUrl');
        if (!params.connectorUid || !params.customerUid || !redirectUrl) return;
    
        const getConnectorRes: Response = await get(
            apiConfig.getHeadlessConnector(params.customerUid, params.connectorUid),
            ''
        );

        if (!getConnectorRes.ok) {
            window.location.href = redirectUrl;
            return;
        }

        const getConnectorResJson = await getConnectorRes.json();

        if (getConnectorResJson.status == 'Active') {
            window.location.href = redirectUrl;
            return;
        }

        const cookieState = {
            redirectUrl: `${window.location.origin}/headless/customers/${params.customerUid}/connector/${params.connectorUid}/end?redirectUrl=${redirectUrl}`,
            name: 'g2f',
            campaignId: window.location.origin
        }
        document.cookie = `g2f=${params.customerUid};path=/`;

        window.location.href = connectorsConfig.connectors[getConnectorResJson.type].get_auth_url(
            btoa(JSON.stringify(cookieState)),
            `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
            getConnectorResJson.configuration.ClientId
        );
        
        setLoading(false);
    };

    return loading ? (
        <LoaderFullPage />
    ) : (
        <div></div>
    );
};

export default HeadlessConnectStart;
