export const authConnectors = {
    xero: {
        baseUrl: 'https://login.xero.com/identity/connect/authorize?',
        responseType: 'code',
        scope: 'openid profile email openid accounting.transactions accounting.contacts accounting.settings offline_access'
    },
    freshbooks: {
        baseUrl: 'https://auth.freshbooks.com/oauth/authorize?',
        responseType: 'code',
        scope: 'user:profile:read user:invoices:read user:clients:read user:payments:read user:reports:read'
    },
    myob: {
        baseUrl: 'https://secure.myob.com/oauth2/account/authorize?',
        responseType: 'code',
        scope: 'CompanyFile'
    },
    zoho: {
        baseUrl: 'https://accounts.zoho.eu/oauth/v2/auth?',
        responseType: 'code',
        scope: 'ZohoInvoice.invoices.READ%20ZohoInvoice.contacts.READ%20ZohoInvoice.fullaccess.all'
    },
    quickbooks: {
        baseUrl: 'https://appcenter.intuit.com/connect/oauth2?',
        responseType: 'code',
        scope: 'com.intuit.quickbooks.accounting'
    },
    zohobooks: {
        baseUrl: 'https://accounts.zoho.eu/oauth/v2/auth?',
        responseType: 'code',
        scope: 'ZohoBooks.invoices.READ%20ZohoBooks.contacts.READ%20ZohoBooks.fullaccess.all%20ZohoBooks.accountants.CREATE'
    },
    sage: {
        baseUrl: "https://www.sageone.com/oauth2/auth/central?filter=apiv3.1&",
        responseType: "code",
        scope: "full_access"
    }
};

export const _cookie1 = 'g2fErpConnectionSystem';
export const _cookie2 = 'g2fErpConnectionConnected';
