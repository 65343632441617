import styled from 'styled-components';

export const AppWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
    padding: 16px;
`;

export const ViewWrapper = styled.div`
    display: flex;
    flex-direction: column;
    min-height: calc(100vh - 266px);
`;

export const AppLoaderContainer = styled.div`
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
