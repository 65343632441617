
import styled from 'styled-components';

export const ProfileContainer = styled.div`
    display: flex;
    flex-direction: column;

    .MuiButtonBase-root:hover {
      background-color: transparent ;
    }
    .MuiListItemIcon-root {
      min-width: 26px;
    }
    
`;

