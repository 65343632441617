import DoneIcon from '@mui/icons-material/Done';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import { themeColors } from 'assets/theme/style';
import { FC, useEffect, useState } from 'react';
import Dropzone from 'react-dropzone';
import { PrimaryButton } from 'components/common/buttons';
import {
    FileUploadContainer,
    FileUploadIconContainer,
    FileUploadText,
    FileUploadButtonContainer,
    FileUploadButtonText,
    UploaderContainer,
    UploaderFilesFilename,
    UploaderFilesIconContainer,
    UploaderFilesListContainer,
    UploaderFilesListItem,
    UploaderFilesListItemLeftSegment,
    UploaderFilesDeleteIconContainer
} from './styled';

interface UploaderProps {
    fileDropHandler: (acceptedFile: File[]) => void;
    singlePreviewMode?: boolean;
    limitBytes?: number;
    preloaded?: string;
    preloadedBlob?: File | Blob;
    acceptedFileTypes?: string[];
    testingTagPage?: string;
    borderColor?: string;
    title?: string;
}

const _IMAGE_TYPES: string[] = ['jpg', 'jpeg', 'png'];

const Uploader: FC<UploaderProps> = ({
    fileDropHandler,
    singlePreviewMode,
    limitBytes = 5000000,
    preloaded,
    preloadedBlob,
    acceptedFileTypes = ['pdf', 'jpg', 'jpeg', 'png'],
    testingTagPage,
    borderColor,
    title = 'Drag and drop file here'
}) => {
    const [uploaded, setUploaded] = useState<File[]>([]);

    useEffect(() => {
        if (preloadedBlob) setUploaded([preloadedBlob as File]);
    }, [preloadedBlob]);

    const fileUploadHandler: (acceptedFiles: File[]) => void = (acceptedFiles) => {
        if (singlePreviewMode && acceptedFiles[0].size > limitBytes)
            return alert(`File must be less than ${limitBytes / 1000}KB`);
        if (
            singlePreviewMode &&
            (acceptedFileTypes.indexOf(acceptedFiles[0].type.split('/')[1]) < 0 && acceptedFileTypes.indexOf(acceptedFiles[0].name.split('.')[1]) < 0)
        ) {
            return alert(
                `File must be one of the following formats: ${acceptedFileTypes.join(' | ')}`
            );
        }
        setUploaded([...uploaded, ...acceptedFiles]);
        fileDropHandler([...uploaded, ...acceptedFiles]);
    };

    const isImage: (file: File) => boolean = (file: File) =>
        _IMAGE_TYPES.indexOf(file.type.split('/')[1]) > 0;

    const removeFileHandler: (index: number) => void = (index) => {
        const mutArr: File[] = uploaded.slice();
        mutArr.splice(index, 1);
        setUploaded(mutArr);
        fileDropHandler(mutArr);
    };

    const renderDropzone: () => boolean = () =>
        (singlePreviewMode && !!!uploaded.length && !!!preloaded) || !singlePreviewMode;

    return (
        <UploaderContainer data-automation-id={`${testingTagPage}-div-file-upload`}>
            {renderDropzone() && (
                <Dropzone onDrop={(acceptedFiles: File[]) => fileUploadHandler(acceptedFiles)}>
                    {({ getRootProps, getInputProps }: any) => (
                        <FileUploadContainer {...getRootProps()} boderColor={borderColor}>
                            <FileUploadIconContainer>
                                <UploadFileIcon />
                            </FileUploadIconContainer>
                            <FileUploadText
                                data-automation-id={`${testingTagPage}-h5-file-upload-text-drag-and-drop-file-here`}
                            >
                                {title}
                            </FileUploadText>
                            <FileUploadButtonContainer>
                                <input {...getInputProps()} />
                                <PrimaryButton
                                    padding="2px 16px"
                                    height="28px"
                                    clickHandler={(e) => e.preventDefault()}
                                >
                                    <FileUploadButtonText
                                        data-automation-id={`${testingTagPage}-p-file-upload-button-text`}
                                    >
                                        Choose file
                                    </FileUploadButtonText>
                                </PrimaryButton>
                            </FileUploadButtonContainer>
                            <p style={{ color: themeColors.body }}>
                                <span style={{ fontWeight: 600 }}>Max file size:</span>{' '}
                                {limitBytes / 1000000}MB
                            </p>
                            <p style={{ color: themeColors.body }}>
                                <span style={{ fontWeight: 600 }}>Allowed formats:</span>{' '}
                                {acceptedFileTypes.join(', ')}
                            </p>
                        </FileUploadContainer>
                    )}
                </Dropzone>
            )}
            {((singlePreviewMode && !!uploaded.length && isImage(uploaded[0])) ||
                (preloaded && singlePreviewMode)) && (
                <img
                    height={85}
                    style={{ objectFit: 'contain', objectPosition: 'left' }}
                    src={!preloaded ? URL.createObjectURL(uploaded[0]) : preloaded}
                    alt=""
                    data-automation-id={`${testingTagPage}-img-file-upload-image`}
                    data-testid="sp-upload-image"
                />
            )}
            <UploaderFilesListContainer>
                {uploaded.map(({ name }, index) => (
                    <UploaderFilesListItem key={index}>
                        <UploaderFilesListItemLeftSegment>
                            <UploaderFilesIconContainer>
                                <DoneIcon fontSize="small" />
                            </UploaderFilesIconContainer>
                            <UploaderFilesFilename
                                data-automation-id={`${testingTagPage}-p-file-upload-file-name`}
                            >
                                {name}
                            </UploaderFilesFilename>
                        </UploaderFilesListItemLeftSegment>
                        <UploaderFilesDeleteIconContainer
                            onClick={() => removeFileHandler(index)}
                            data-testid="sp-delete-file"
                        >
                            <DeleteForeverIcon fontSize="small" />
                        </UploaderFilesDeleteIconContainer>
                    </UploaderFilesListItem>
                ))}
            </UploaderFilesListContainer>
        </UploaderContainer>
    );
};

export default Uploader;
