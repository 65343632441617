import styled from 'styled-components';

export const FooterContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 85px;
    align-items: center;
    justify-content: center;
`;

export const FooterTextContainer = styled.div`
    transform: translate(-65px, 15px);
`;
