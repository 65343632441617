import logo from 'assets/logos/finecta_logo.png';
import Typography from 'components/typography';
import { FC } from 'react';
import { FooterContainer, FooterTextContainer } from './styled';

const Footer: FC = () => {
    return (
        <>
            {
                !window.location.pathname.includes('/headless/') &&
                <FooterContainer>
                    <FooterTextContainer>
                        <Typography tag="subheading1">Powered By</Typography>
                    </FooterTextContainer>
                    <img height="100px" src={logo} alt="finecta logo" />
                </FooterContainer>
            }
        </>
    );
};

export default Footer;
