/* eslint-disable no-useless-concat */
/* eslint-disable react-hooks/exhaustive-deps */
import { apiConfig, get, post, put } from 'api/apiConfig';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { _cookie1, _cookie2 } from 'lib/constants';
import { cookieExists, stateCookieExists } from 'lib/cookie';
import { IConfig } from 'lib/interfaces';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import Select from 'react-select';
import connectorsConfig from '../../config/config';
import ERPSelectionTile from './ERPSelectionTile';
import EDI from './EDI';
import FIDESConfigurer from './FIDES';
import ThomsonReutersConfigurer from './ThomsonReuters';
import CSV from './CSV';
import Typography from 'components/typography';

const tileContainerStyles: CSSProperties = {
    width: 'fit-content',
    display: 'flex',
    flexDirection: 'row',
    borderRadius: '6px',
    padding: '16px',
    gap: '1rem',
    height: 'auto'
};

const buttonStyles: CSSProperties = {
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid #E4E4E4',
    backgroundColor: '#FAFAFA',
    color: '#0032A0',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: '16px'
};

export interface IERPSoftware {
    display_name: string;
    logo_url: string;
    get_auth_url: (state: any, redirectPath: string, clientId: string) => string;
}

const ERPSelectionTiles: FC<IConfig> = ({
    setup,
    connectionCompleteCallback,
    processingConnectionCallback
}) => {
    const [code, setCode] = useState<string | null>(null);
    const [connector, setConnector] = useState<string | null>(null);
    const [location, setLocation] = useState<string | null>(null);
    const [tenantId, setTenantId] = useState<string | null>(null);
    const [isValidRequest, setIsValidRequest] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [processingConnectorConnection, setProcessingConnectorConnection] =
        useState<boolean>(true);
    const [step, setStep] = useState<number>(1);
    const [selectedType, setSelectedType] = useState<string | null>(null);
    const [connectorUID, setConnectorUID] = useState<string | null>(null);
    const [tenants, setTenants] = useState<any[]>([]);
    const [tenant, setTenant] = useState({ selectedOption: { value: '', label: '' } });
    const [activeConnector, setActiveConnector] = useState<string | null>();
    const [connectors, setConnectors] = useState<any[]>([]);
    const {
        redirectPath,
        customerUID,
        token,
        platformRedirectPath,
        connectorTypes,
        partnerTenantId
    } = setup;
    const { register, handleSubmit, setValue } = useForm();
    const cookieState = {
        redirectUrl: platformRedirectPath,
        name: 'g2f',
        campaignId: window.location.origin
    };
    document.cookie = `g2f=${customerUID};path=/`;

    useEffect(() => {
        if (token && customerUID && connectorTypes) getConnectorStatus();
    }, [setup]);

    useEffect(() => {
        if (Boolean(activeConnector) && connectionCompleteCallback) connectionCompleteCallback();
    }, [activeConnector]);

    useEffect(() => {
        if (Boolean(processingConnectorConnection) && processingConnectionCallback)
            processingConnectionCallback(processingConnectorConnection);
    }, [processingConnectorConnection]);

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const isComplete: string | null = urlParams.get('isComplete');

        if (isComplete && connectionCompleteCallback) connectionCompleteCallback();

        const code: string | null = urlParams.get('code');
        const state: string | null = urlParams.get('state');
        const connector: string | null = urlParams.get('connector');

        if (!code || !state || !connector) return;

        setConnector(connector);
        setTenantId(urlParams.get(connectorsConfig.connectors[connector]?.tenant_id));
        setLocation(urlParams.get(connectorsConfig.connectors[connector]?.location));
        setCode(code);
        setStep(0);
        setIsValidRequest(stateCookieExists(state || '') && !!code);
    }, [window.location.search]);

    useEffect(() => {
        if (isValidRequest) saveConnector();
    }, [isValidRequest]);

    useEffect(() => {
        if (step === 2) {
            getTenants();
            register('tenantId');
        }
    }, [step, connectorUID]);

    useEffect(() => {
        mappedConnectors();
    }, [activeConnector, connectorTypes]);

    //https://staging.tlcfinance.global

    const saveConnector: () => void = async () => {
        setLoading(true);
        try {
            const addConnectorResponse: Response = await post(
                apiConfig.addConnector(customerUID),
                {
                    customerUid: customerUID,
                    code,
                    redirectUrl: redirectPath,
                    tenantId,
                    location,
                    connectorType: connector?.toLowerCase(),
                    partnerTenantId
                },
                token
            );
            if (addConnectorResponse.status >= 400 && addConnectorResponse.status < 600) {
                setIsValidRequest(false);
                return;
            }
            const addConnectorResponseJson = await addConnectorResponse.json();

            if (addConnectorResponseJson.configuration.TenantId) {
                tenantIdSuccessful(addConnectorResponseJson.type, addConnectorResponseJson.status);
            } else {
                setConnectorUID(addConnectorResponseJson.connectorUid);
                setStep(2);
            }
        } catch (error) {
            if (cookieExists(_cookie1))
                document.cookie = _cookie1 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
            if (cookieExists(_cookie2))
                document.cookie = _cookie2 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        } finally {
            setLoading(false);
            if (connectionCompleteCallback) connectionCompleteCallback();
        }
    };

    const tenantIdSuccessful: (type: string, status: string) => void = (type, status) => {
        saveDefaultConnectorSchedule();
        triggerImport();
        setStep(3);
        document.cookie = `${_cookie1}=${type}`;
        document.cookie = `${_cookie2}=${status?.toLowerCase()}`;
        setActiveConnector(type);
        setProcessingConnectorConnection(false);
    };

    const triggerImport: () => Promise<Response> = async () =>
        await get(apiConfig.triggerInvoiceImport(customerUID), token);

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(
            apiConfig.updateSchedule(customerUID),
            {
                schedule: '0 0 0 * * *'
            },
            token
        );

    const options = Object.keys(tenants).map((key) => ({ value: key, label: tenants[key] }));

    const getConnectorStatus: () => Promise<void> = async () => {
        const getConnectorResponse = await get(apiConfig.getConnector(customerUID), token);

        if (!getConnectorResponse.ok) return;

        const { status, type } = await getConnectorResponse.json();

        if (status && status === 'Active') {
            document.cookie = `${_cookie1}=${type}`;
            document.cookie = `${_cookie2}=${status?.toLowerCase()}`;
            setActiveConnector(type);
        }
        if (!status || status !== 'Active') {
            setActiveConnector(null);
            if (cookieExists(_cookie1))
                document.cookie =
                    _cookie1 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT' + ';path=/;';
            if (cookieExists(_cookie2))
                document.cookie =
                    _cookie2 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT' + ';path=/;';
        }

        setProcessingConnectorConnection(false);
    };

    const getTenants: () => Promise<void> = async () => {
        if (!customerUID) return;
        const getTenantsResponse = await get(apiConfig.getTenants(customerUID), token);

        if (!getTenantsResponse.ok) return;

        const getTenantsResponseJson = await getTenantsResponse.json();

        if (Object.keys(getTenantsResponseJson).length > 0) setTenants(getTenantsResponseJson);
    };

    const handleChange: (selectedOption: any, action: any) => void = (selectedOption, action) => {
        setValue(action.name, selectedOption.value);
        setTenant({ selectedOption });
    };

    const updateConnector: () => Promise<void> = async () => {
        if (!customerUID) return;
        setLoading(true);

        try {
            const updateConnectorResponse = await put(
                apiConfig.updateConnector(customerUID),
                {
                    customerUid: customerUID,
                    code,
                    redirectUrl: redirectPath,
                    tenantId: tenant.selectedOption.value,
                    location,
                    connectorType: connector?.toLowerCase(),
                    partnerTenantId
                },
                token
            );

            if (!updateConnectorResponse.ok) return;

            triggerImport();
            setStep(3);
            await getConnectorStatus();
        } catch (error) {
            if (cookieExists(_cookie1))
                document.cookie = _cookie1 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
            if (cookieExists(_cookie2))
                document.cookie = _cookie2 + '=' + '' + ';expires=Thu, 01 Jan 1970 00:00:01 GMT';
        } finally {
            setLoading(false);
        }
    };

    const mappedConnectors: () => void = () => {
        let connectorsArr = Object.keys(connectorsConfig.connectors)
            .filter((x) => connectorTypes?.findIndex((p) => p.type === x) !== -1)
            .map((c) => {
                const connectorType = connectorTypes?.find((ct) => ct.type === c);

                if (connectorType)
                    connectorsConfig.connectors[c].logo_url = connectorType.iconDataUrl;

                connectorsConfig.connectors[c].connected = c === activeConnector?.toLowerCase();

                return connectorsConfig.connectors[c];
            });
        if (activeConnector !== undefined && activeConnector) {
            connectorsArr = connectorsArr.filter((c) => c.connected);
        }

        setConnectors(connectorsArr);
    };

    const tileSelected: (type: string) => void = (type) => setSelectedType(type);

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: loading ? '100%' : 'fit-content'
            }}
        >
            {loading && (
                <div
                    style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <LoaderInPage size={20} />
                </div>
            )}
            {!selectedType && !activeConnector && !loading && (
                <Typography tag="subheading1">
                    Please select your accounting system from the choices below:
                </Typography>
            )}
            {!loading && step !== 2 && !selectedType && (
                <div style={tileContainerStyles}>
                    {connectors.map((s, i) => (
                        <ERPSelectionTile
                            key={i}
                            {...s}
                            redirectPath={redirectPath}
                            customerUID={customerUID}
                            state={cookieState}
                            clientId={connectorTypes?.find((x) => x.type === s.type)?.clientId}
                            callback={tileSelected}
                        />
                    ))}
                </div>
            )}
            {!loading && step === 2 && !selectedType && (
                <form className="form" onSubmit={handleSubmit(updateConnector)}>
                    <p style={{ marginBottom: '8px' }}>
                        We have configured your connector but we need to know which tenant to use
                        before it can be activated.
                    </p>
                    <Select
                        id="tenantId"
                        name="tenantId"
                        options={options}
                        placeholder="Tenant"
                        value={tenant.selectedOption}
                        onChange={handleChange}
                    />
                    <button style={buttonStyles} type="submit">
                        Submit
                    </button>
                </form>
            )}
            {!loading && selectedType && partnerTenantId && (
                <div>
                    {selectedType === 'edi' && (
                        <EDI
                            customerUID={customerUID}
                            partnerTenantId={partnerTenantId}
                            token={token}
                            redirectUrl={platformRedirectPath}
                        />
                    )}
                    {selectedType === 'fides' && (
                        <FIDESConfigurer
                            customerUID={customerUID}
                            partnerTenantId={partnerTenantId}
                            token={token}
                            redirectUrl={platformRedirectPath}
                        />
                    )}
                    {selectedType === 'tr' && (
                        <ThomsonReutersConfigurer
                            customerUID={customerUID}
                            partnerTenantId={partnerTenantId}
                            token={token}
                            redirectUrl={platformRedirectPath}
                        />
                    )}
                    {selectedType === 'csv' && <CSV customerUID={customerUID} token={token} />}
                </div>
            )}
        </div>
    );
};

export default ERPSelectionTiles;
