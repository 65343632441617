export const themeColors = {
    primary: '#fec70b',
    secondary: '#393939',
    accent: '#6d5fa5',
    grey: '#eeeeee',
    darkGrey: '#888888',
    inputDisabled: '#C4C4C4',
    body: '#000000',
    primaryButton: '#6D5FA6',
    secondaryButton: '#393939',
    white: '#ffffff',
    error: '#E4333E',
    success: '#3CC60A',
};

export const inputSxStyles = {
    '& label.Mui-focused': {
        color: themeColors.secondary
    },
    '& .MuiOutlinedInput-root': {
        '&:hover fieldset': {
            borderColor: themeColors.primary
        },
        '&.Mui-focused fieldset': {
            borderColor: themeColors.primary
        }
    }
};
