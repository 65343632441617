import { themeColors } from 'assets/theme/style';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import styled from 'styled-components';
import { typography } from 'assets/theme/typography';

export const NimblaWelcomeContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 80%;
    padding: 16px;
    border-radius: 16px;
    border: 1px solid ${themeColors.accent};
`;

export const NimblaWelcomeTopRow = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

export const NimblaWelcomeBodyContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 16px;
    margin-bottom: 16px;
`;

export const NimblaWelcomeConnectedContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 32px;
`;

export const NimblaWelcomeBodyCheckboxWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 5px;
`;

export const DisabledImage = styled.img`
  filter: grayscale(100%);
  opacity: 0.5;
  pointer-events: none;
`;

export const NimblaFloat = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
`;

export const ButtonStyled = styled.button<ButtonProps>`
display: flex;
height: ${({ height }) => height || '36px'};
width: ${({ width }) => width || '100%'};
border: none;
justify-content: center;
align-items: center;
padding: ${({ padding }) => padding || '6px 24px'};
border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
background-color: ${({ backgroundColor, disabled }) =>
    disabled ? themeColors.inputDisabled : backgroundColor || themeColors.primaryButton};
color: ${({ textColor }) => textColor || themeColors.white};
${typography.bodySemiBold};
border-radius: 6px;
cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;