import { useMsal } from '@azure/msal-react';
import { Logout } from '@mui/icons-material';
import { Box, IconButton, ListItemIcon, Menu, MenuItem } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { FC, MouseEvent, useState } from 'react';
import { ProfileContainer } from './styled';

const Profile: FC = () => {
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const { instance } = useMsal();

    const handleClick: (event: MouseEvent<HTMLElement>) => void = (event) =>
        setAnchorEl(event.currentTarget);

    const handleClose: () => void = () => {
        setAnchorEl(null);
    };

    const logout: () => void = () => {
        instance.logoutRedirect();
        handleClose();
    };

    return (
        <ProfileContainer>
            <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                >
                    <Avatar sx={{ width: 32, height: 32 }}>
                        {instance.getActiveAccount()?.name?.charAt(0).toUpperCase()}
                    </Avatar>
                </IconButton>
            </Box>
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0
                        }
                    }
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                <MenuItem onClick={logout}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                </MenuItem>
            </Menu>
        </ProfileContainer>
    );
};

export default Profile;
