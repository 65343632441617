/* eslint-disable react-hooks/exhaustive-deps */
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { IMsalContext, useMsal } from '@azure/msal-react';
import {
    Alert,
    Autocomplete,
    Checkbox,
    FormControl,
    FormControlLabel,
    FormGroup,
    FormLabel,
    Radio,
    RadioGroup,
    TextField
} from '@mui/material';
import { apiConfig, b2cPolicies, get, post, put } from 'api/apiConfig';
import { inputSxStyles, themeColors } from 'assets/theme/style';
import ERPSelectionTiles from 'components/ERPSelectionTiles';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { PrimaryButton } from 'components/common/buttons';
import Typography from 'components/typography';
import { ICompaniesHouseData, ICompaniesHouseItem } from 'lib/interfaces/companyHouse.interface';
import G2FSetup from 'lib/setup';
import { FC, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router';
import { ConnectorType } from 'utils/interfaces/ConnectorType';
import {
    AcceptBottomContainer,
    AcceptButtonContainer,
    AcceptContainer,
    AcceptHeadingContainer,
    AcceptReportContainer,
    AcceptTopContainer
} from './styled';
import { authResult } from 'lib/authConfig';

interface IAutocompleteOption {
    id: string;
    label: string;
    name: string;
}

const Accept: FC = () => {
    const { instance, accounts, inProgress }: IMsalContext = useMsal();
    const params = useParams();
    const navigate = useNavigate();
    const [name, setName] = useState<string>('');
    const [token, setToken] = useState<string>('');
    const [tokenExpiry, setTokenExpiry] = useState<Date | null>();
    const [error, setError] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [submittedName, setSubmittedName] = useState<boolean>(false);
    const [customerUid, setCustomerUid] = useState<string>('');
    const [accountingSystem, setAccountingSystem] = useState<string>('');
    const [isRevoked, setIsRevoked] = useState<boolean>(false);
    const [agreedToTermsAndConditions, setAgreedToTermsAndConditions] = useState<boolean>(false);
    const [connectorTypes, setConnectorTypes] = useState<ConnectorType[]>([]);
    const [autocompleteTerm, setAutocompleteTerm] = useState<string>('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<IAutocompleteOption[]>([]);
    const [selectedAutocompleteOption, setSelectedAutocompleteOption] =
        useState<IAutocompleteOption | null>(null);
    const [countrySelection, setCountrySelection] = useState<'uk' | 'restOfWorld'>('uk');

    let count = 0;
    let accountCount = 0;

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ],
        extraQueryParameters: {
            campaignId: window.location.hostname
        },
        authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority,
        account: accounts[0]
    };

    useEffect(() => {
        if (count === 0) {
            setLoading(true);
            count++;
        }
    }, []);

    useEffect(() => {
        if (!tokenExpiry) {
            return;
        }

        const timer = setTimeout(() => {
            navigate('/?unauthenticated=true');
        }, tokenExpiry.getTime() - Date.now());
            
        return () => {
            clearTimeout(timer);
        };
    }, [tokenExpiry]);

    useEffect(() => {
        if (autocompleteTerm.length > 3) companiesSearch(autocompleteTerm);
    }, [autocompleteTerm]);

    useEffect(() => {
        if (accounts.length > 0 && accountCount === 0) {
            redeemInvite();
            accountCount++;
        }
    }, [inProgress, accounts]);

    const redeemInvite: () => void = async () => {
        const instanceResponse = await authResult(request, instance);
        if (!instanceResponse) {
            navigate('/?unauthenticated=true');
        } else {
            const accessToken: string = instanceResponse.accessToken;
            setToken(accessToken);
            setTokenExpiry(instanceResponse.expiresOn);

            if (!params?.partnerUid) return;

            const getInviteRes: Response = await get(
                apiConfig.partnerInvite(params.partnerUid),
                accessToken
            );

            if (!getInviteRes.ok) return;

            const getInviteResJson = await getInviteRes.json();

            if (getInviteResJson.status === 'Redeemed') {
                getCustomer();
                setLoading(false);
                return;
            }

            if (getInviteResJson.status !== 'Pending') {
                setLoading(false);
                setIsRevoked(true);
                return;
            }

            const redeemInviteRes: Response = await put(
                apiConfig.partnerInvite(params.partnerUid),
                { status: 'Redeemed' },
                accessToken
            );

            if (!redeemInviteRes.ok) {
                setError(true);
            }

            getCustomer();

            setLoading(false);
        }
    };

    const getCustomer: () => void = async () => {
        const instanceResponse = await authResult(request, instance);
        if (!instanceResponse) {
            navigate('/?unauthenticated=true');
        } else {
            if (loading || !params.partnerUid) return;
            setLoading(true);

            const getCustomersRes: Response = await get(
                apiConfig.customers,
                instanceResponse.accessToken
            );

            if (!getCustomersRes.ok) {
                setError(true);
                setLoading(false);
                return;
            }

            const getCustomersResJson = await getCustomersRes.json();

            if (getCustomersResJson.customers.length === 0) {
                return;
            }
            setCustomerUid(getCustomersResJson.customers[0].id);
            setLoading(false);
            setSubmittedName(true);
            setAgreedToTermsAndConditions(true);

            const mappedData: Response = await get(
                apiConfig.getConnectorTypes(getCustomersResJson.customers[0].id, params.partnerUid),
                instanceResponse.accessToken
            );

            if (!mappedData.ok) {
                setError(true);
                setLoading(false);
                return;
            }

            const mappedDataJson = await mappedData.json();
            setConnectorTypes(mappedDataJson);
        }
    };

    const companiesSearch: (term: string) => void = async (term) => {
        const instanceResponse = await authResult(request, instance);
        if (!instanceResponse) {
            navigate('/?unauthenticated=true');
        } else {
            const companiesHouseResponse = await get(
                apiConfig.companiesHouseSearch(term),
                instanceResponse.accessToken
            );

            if (!companiesHouseResponse.ok) return;

            const companiesHouseResponseJson: ICompaniesHouseData = await companiesHouseResponse.json();

            const mappedData: IAutocompleteOption[] = companiesHouseResponseJson.items.map(
                (c: ICompaniesHouseItem) => ({
                    id: c.companyNumber || '',
                    label: `${c.title || ''} - ${c.companyNumber || ''}`,
                    name: c.title || ''
                })
            );

            setAutocompleteOptions(mappedData);
        }
    };

    const createClickHandler: () => void = async () => {
        if (loading || !params.partnerUid) return;
        setLoading(true);

        const payload: { name: string; partnerId: string; registrationNumber?: string; mainInstance: string} = {
            name: countrySelection === 'uk' ? selectedAutocompleteOption?.name || '' : name,
            partnerId: params.partnerUid,
            mainInstance: window.location.hostname
        };

        if (countrySelection === 'uk')
            payload.registrationNumber = selectedAutocompleteOption?.id || '';

        const postFlowRes: Response = await post(apiConfig.flows, payload, token);

        if (!postFlowRes.ok) {
            setError(true);
            setLoading(false);
            return;
        }

        const flowResJson = await postFlowRes.json();

        const getFlowRes: Response = await get(apiConfig.getFlow(flowResJson.linkUid), token);

        if (!getFlowRes.ok) {
            setError(true);
            setLoading(false);
            return;
        }

        const getFlowResJson = await getFlowRes.json();

        setCustomerUid(getFlowResJson.customerUid);
        setLoading(false);
        setSubmittedName(true);

        const mappedData: Response = await get(
            apiConfig.getConnectorTypes(getFlowResJson.customerUid, params.partnerUid),
            token
        );

        if (!mappedData.ok) {
            setError(true);
            setLoading(false);
            return;
        }

        const mappedDataJson = await mappedData.json();
        setConnectorTypes(mappedDataJson);
    };

    return (
        <AcceptContainer>
            <AcceptTopContainer>
                {isRevoked && (
                    <AcceptHeadingContainer>
                        <Typography tag="h1" fontWeight="regular">
                            Your invite{' '}
                            <span style={{ fontWeight: 700 }}>has already been revoked.</span>
                        </Typography>

                        <Typography tag="subheading1">
                            Please contact your partner to request a new invitation.
                        </Typography>
                    </AcceptHeadingContainer>
                )}
                {!isRevoked && (
                    <>
                        <AcceptHeadingContainer>
                            <Typography tag="h1" fontWeight="bold">
                                Connect to your accounting system
                            </Typography>

                            {!submittedName && (
                                <FormGroup>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={agreedToTermsAndConditions}
                                                sx={{
                                                    color: themeColors.secondary,
                                                    '&.Mui-checked': {
                                                        color: themeColors.primary
                                                    },
                                                    '&:hover': {
                                                        backgroundColor: `${themeColors.primary}20`
                                                    }
                                                }}
                                                onChange={(
                                                    event: React.ChangeEvent<HTMLInputElement>
                                                ) =>
                                                    setAgreedToTermsAndConditions(
                                                        event.target.checked
                                                    )
                                                }
                                            />
                                        }
                                        label={
                                            <p>
                                                I agree to abide by Finecta's terms and conditions
                                                of operation found{' '}
                                                <span
                                                    style={{
                                                        color: themeColors.primary,
                                                        cursor: 'pointer',
                                                        zIndex: 100
                                                    }}
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        window.open(
                                                            `${window.location.origin}/termsandconditions`,
                                                            '_BLANK'
                                                        );
                                                    }}
                                                >
                                                    here
                                                </span>
                                                .
                                            </p>
                                        }
                                    />
                                </FormGroup>
                            )}
                            {error && (
                                <Alert severity="error">
                                    Something went wrong. Please contact us.
                                </Alert>
                            )}
                        </AcceptHeadingContainer>
                        {agreedToTermsAndConditions && !submittedName && (
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                <FormControl>
                                    <FormLabel id="country-buttons-radio-group-label">
                                        Where is your company registered?
                                    </FormLabel>
                                    <RadioGroup
                                        row
                                        aria-labelledby="country-buttons-radio-group-label"
                                        name="country-radio-buttons-group"
                                        value={countrySelection}
                                        onChange={(e) => setCountrySelection(e.target.value as any)}
                                    >
                                        <FormControlLabel
                                            value="uk"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: themeColors.primary,
                                                        '&.Mui-checked': {
                                                            color: themeColors.primary
                                                        }
                                                    }}
                                                />
                                            }
                                            label="UK"
                                            defaultChecked
                                        />
                                        <FormControlLabel
                                            value="restOfWorld"
                                            control={
                                                <Radio
                                                    sx={{
                                                        color: themeColors.primary,
                                                        '&.Mui-checked': {
                                                            color: themeColors.primary
                                                        }
                                                    }}
                                                />
                                            }
                                            label="Rest of World"
                                        />
                                    </RadioGroup>
                                </FormControl>
                                <div
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'row',
                                        alignItems: 'center',
                                        justifyContent: 'space-between',
                                        gap: '16px'
                                    }}
                                >
                                    {countrySelection === 'uk' && (
                                        <Autocomplete
                                            disablePortal
                                            id="finecta-register-company"
                                            options={autocompleteOptions}
                                            sx={{ ...inputSxStyles, width: '100%' }}
                                            disabled={submittedName && Boolean(customerUid)}
                                            onChange={(_, value: IAutocompleteOption) =>
                                                setSelectedAutocompleteOption(value)
                                            }
                                            renderInput={(params: any) => (
                                                <TextField
                                                    {...params}
                                                    label="Legal company name"
                                                    fullWidth
                                                    onChange={(e: any) =>
                                                        setAutocompleteTerm(e.target.value)
                                                    }
                                                    autoFocus
                                                />
                                            )}
                                        />
                                    )}
                                    {countrySelection === 'restOfWorld' && (
                                        <TextField
                                            {...params}
                                            label="Company name"
                                            fullWidth
                                            sx={{ ...inputSxStyles }}
                                            onChange={(e: any) => setName(e.target.value)}
                                            autoFocus
                                            disabled={submittedName && Boolean(customerUid)}
                                        />
                                    )}
                                    <PrimaryButton
                                        disabled={loading || submittedName}
                                        clickHandler={createClickHandler}
                                        width="fit-content"
                                        height="54px"
                                    >
                                        {loading ? (
                                            <LoaderInPage />
                                        ) : submittedName && customerUid ? (
                                            'Saved!'
                                        ) : (
                                            'Save'
                                        )}
                                    </PrimaryButton>
                                </div>
                                {!selectedAutocompleteOption && countrySelection === 'uk' ? (
                                    <span style={{ paddingLeft: '4px' }}>
                                        <Typography tag="body1" textColor="grey">
                                            Please enter more than 3 characters.
                                        </Typography>
                                    </span>
                                ) : (
                                    <></>
                                )}
                            </div>
                        )}
                    </>
                )}
                {submittedName && customerUid && token && agreedToTermsAndConditions && (
                    <>
                        <ERPSelectionTiles
                            setup={
                                new G2FSetup(
                                    token,
                                    `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                                    customerUid,
                                    `${window.location.origin}/app/connection?customerUid=${customerUid}&partnerUid=${params.partnerUid}`,
                                    '',
                                    connectorTypes,
                                    params.partnerUid
                                )
                            }
                        />
                    </>
                )}
            </AcceptTopContainer>
            {submittedName && customerUid && token && agreedToTermsAndConditions && (
                <AcceptBottomContainer>
                    <Typography tag="subheading1">
                        Your accounting system not here? Please tell us what you use and we'll get
                        back to you when it's available.
                    </Typography>
                    <AcceptReportContainer>
                        <TextField
                            sx={{ ...inputSxStyles, flex: 1 }}
                            id="finecta-report-system"
                            label="Your accounting system"
                            variant="outlined"
                            fullWidth
                            value={accountingSystem}
                            onChange={(e) => setAccountingSystem(e.target.value)}
                        />
                        <AcceptButtonContainer>
                            <a
                                href={
                                    Boolean(accountingSystem)
                                        ? `mailto:help@finecta.com?subject=Please add ${accountingSystem}`
                                        : ''
                                }
                            >
                                <PrimaryButton
                                    disabled={!accountingSystem}
                                    width="fit-content"
                                    clickHandler={(e) => {
                                        if (!Boolean(accountingSystem)) e.preventDefault();
                                    }}
                                >
                                    Notify Us
                                </PrimaryButton>
                            </a>
                        </AcceptButtonContainer>
                    </AcceptReportContainer>
                </AcceptBottomContainer>
            )}
        </AcceptContainer>
    );
};

export default Accept;
