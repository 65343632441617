
import { themeColors } from 'assets/theme/style';
import { typography } from 'assets/theme/typography';
import { Accordion } from '@mui/material';
import styled from 'styled-components';

export const CSVConfigurerContainer = styled.form`
    display: flex;
    width: 500px;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin: auto;
`;

export const SFTPConfigurerContainer = styled.form`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    margin-bottom: 2%;
`;

export const SelectContainer = styled.div`
    display: flex;
    width: 500px;
    flex-direction: column;
    gap: 6px;
    
    label {
      ${typography.bodySemiBold};
    }
`;

export const TextContainer = styled.div`
    display: flex;
    width: 500px;
    align-items: center;
    gap: 20px;

    label {
      ${typography.bodyBold};
    }
`;


export const NoticeContainer = styled.div`
  border: 1px solid ${themeColors.darkGrey};
  padding: 10px;
  width: 80%;
  border-radius: 6px;
  a {
    color: inherit;
    font-weight: 700;
  }
  label {
    width: 100%;
    display: block;
    text-align: center;
    padding-bottom:2%;
    ${typography.bodyBold};
  }
`;


export const MessageContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
`;

export const SuccessMessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${themeColors.success};
  color: ${themeColors.success};
  padding: 10px;
  width: 80%;
  border-radius: 6px;
`;

export const ErrorMessageContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  border: 1px solid ${themeColors.error};
  color: ${themeColors.error};
  padding: 10px;
  width: 80%;
  border-radius: 6px;
`;

export const ConfigurationsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 20px;
  margin-bottom: 10%;
`;

export const AccordionStyled = styled(Accordion)`
    &.MuiPaper-root {
      box-shadow: 0px 4px 8px 0px rgba(48, 49, 51, 0.10), 0px 0px 1px 0px rgba(48, 49, 51, 0.05);
        ::before {
            display: none;
        }

        .MuiAccordionDetails-root {
          padding: 8px 32px 32px;
        }

        .MuiAccordionSummary-root {
            width: 100%;
            padding: 8px 32px;
            align-items: center;
            ${typography.bodyBold};
            &.Mui-expanded {
                margin: 0;
            }
        }
    }

    .MuiAccordionSummary-content {
        margin: 0;
    }
    .MuiAccordionSummary-expandIconWrapper {
        color: ${themeColors.body};
        transform: rotate(-90deg);
        &.Mui-expanded {
            transform: rotate(360deg);
        }
    }
    .MuiAccordionSummary-root {
        &.Mui-disabled {
            opacity: 1;
        }
    }
    &.Mui-disabled {
        background-color: transparent !important;
    }
`;