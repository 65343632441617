import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const AcceptContainer = styled(G2FSSContainer)`
    flex: 1;
    justify-content: space-between;
`;

export const AcceptHeadingContainer = styled(G2FSSContainer)``;

export const AcceptReportContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
`;

export const AcceptButtonContainer = styled.div`
    display: flex;
    flex-direction: column;
    a {
        text-decoration: none;
    }
`;

export const AcceptTopContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 32px;
`;

export const AcceptBottomContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: 24px;
`;
