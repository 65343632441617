import styled from 'styled-components';
import { ButtonProps } from 'utils/interfaces/button/button.interface';
import { typography } from 'assets/theme/typography';
import { themeColors } from 'assets/theme/style';

export const ButtonStyled = styled.button<ButtonProps>`
    display: flex;
    height: ${({ height }) => height || '36px'};
    width: ${({ width }) => width || '100%'};
    border: none;
    justify-content: center;
    align-items: center;
    padding: ${({ padding }) => padding || '6px 24px'};
    border: ${({ borderColor }) => (borderColor ? `1px solid ${borderColor}` : 'none')};
    background-color: ${({ backgroundColor, disabled }) =>
        disabled ? themeColors.inputDisabled : backgroundColor || themeColors.primaryButton};
    color: ${({ textColor }) => textColor || themeColors.white};
    ${typography.bodySemiBold};
    border-radius: 6px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
`;
