import { FC } from 'react';
import { IERPSoftware } from '../ERPSelectionTiles';

interface IERPSelectionTile extends IERPSoftware {
    redirectPath: string;
    state: any;
    connected: boolean;
    clientId: string | undefined;
    type: string | undefined;
    customerUID: string | undefined;
    callback: (connectorType: string) => string;
}

const ERPSelectionTile: FC<IERPSelectionTile> = ({
    display_name: name,
    logo_url: logo,
    get_auth_url: getPath,
    callback,
    redirectPath,
    state,
    connected,
    clientId,
    type
}) => {
    // const [hoverActive, setHoverActive] = useState<boolean>(false);
    const imgStyles = {
        'vertical-align': 'middle',
        width: '100px',
        height: 'auto'
    };
    const helperStyles = {
        display: 'inline-block',
        height: '100%',
        'vertical-align': 'middle'
    };
    const tileStyles = {
        width: '100px',
        height: '100px',
        padding: '20px',
        borderRadius: '12px',
        border: `1px solid ${connected ? '#15B672' : 'transparent'}`,
        backgroundColor: '#F5F5F5',
        cursor: 'pointer'
    };

    const stateFormatter: () => any = () => {
        const hasQueryString = state.redirectUrl.split('?').length > 1;
        if (!hasQueryString) state.redirectUrl = `${state.redirectUrl}?connector=${type}`;
        state.redirectUrl = `${state.redirectUrl}&connector=${type}`;
        return state;
    };

    return (
        <div
            style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                alignItems: 'center',
                justifyContent: 'center',
                position: 'relative'
            }}
        >
            {connected && (
                <div
                    style={{
                        height: '12px',
                        width: '12px',
                        borderRadius: '24px',
                        backgroundColor: '#15B672',
                        position: 'absolute',
                        top: '-4px',
                        right: '-4px',
                        zIndex: 2
                    }}
                ></div>
            )}
            <div style={tileStyles}>
                <span style={helperStyles}></span>
                <img
                    style={imgStyles}
                    src={logo}
                    alt={`${name}-logo`}
                    onClick={() => {
                        if (!connected && clientId) {
                            window.open(
                                getPath(
                                    btoa(JSON.stringify(stateFormatter())),
                                    redirectPath,
                                    clientId
                                ),
                                '_self'
                            );
                        } else if (type && !clientId) {
                            callback(type);
                        }
                    }}
                />
            </div>
            {/* {hoverActive && (
                <div
                    style={{
                        height: '100px',
                        width: '100px',
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        borderRadius: '12px',
                        backgroundColor: '#0032A040',
                        border: '1px solid #0032A0',
                        zIndex: 1,
                        cursor: 'pointer'
                    }}
                ></div>
            )} */}
            <p style={{ margin: 0 }}>{name}</p>
        </div>
    );
};

export default ERPSelectionTile;
