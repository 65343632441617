import { TextField } from '@mui/material';
import { inputSxStyles } from 'assets/theme/style';
import { PrimaryButton } from 'components/common/buttons';
import ERPSelectionTiles from 'components/ERPSelectionTiles';
import Typography from 'components/typography';
import { FC } from 'react';
import {
    InvitationButtonContainer,
    InvitationContainer,
    InvitationReportContainer,
    InvitationSectionContainer
} from './styled';

const Invitation: FC = () => {
    return (
        <InvitationContainer>
            <InvitationSectionContainer>
                <Typography tag="h1" fontWeight="regular">
                    Invitation from{' '}
                    <Typography tag="span" fontWeight="bold">
                        Finecta Ltd
                    </Typography>
                </Typography>
                <Typography tag="h3" fontWeight="regular">
                    To share your financial information, first select your accounting system:
                </Typography>
                <Typography tag="subheading1">
                    (Once clicked you will be asked to login and share your information.)
                </Typography>
                <ERPSelectionTiles
                    setup={{
                        token: '',
                        redirectPath: `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                        customerUID: '',
                        platformRedirectPath: `${window.location.origin}/`
                    }}
                />
            </InvitationSectionContainer>
            <InvitationReportContainer>
                <TextField
                    sx={{ ...inputSxStyles, flex: 1 }}
                    id="finecta-report-system"
                    label="Your accounting system not here? Let us know which one you use. Thanks."
                    variant="outlined"
                />
                <InvitationButtonContainer>
                    <PrimaryButton>Notify Us</PrimaryButton>
                </InvitationButtonContainer>
            </InvitationReportContainer>
        </InvitationContainer>
    );
};

export default Invitation;
