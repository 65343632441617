import Uploader from 'components/Uploader';
import { apiConfig, post, postFile, put } from 'api/apiConfig';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import CheckIcon from '@mui/icons-material/Check';

import { FC, useState } from 'react';
import {
    EDIConfigurerContainer,
    SelectContainer,
    MessageContainer,
    ErrorMessageContainer,
    SuccessMessageContainer
} from './styled';
import { PrimaryButton } from 'components/common/buttons';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import { useForm } from 'react-hook-form';
import Select from 'components/forms/inputs/Select';
import { themeColors } from 'assets/theme/style';
import { ISelectData } from 'lib/interfaces';
import { SelectChangeEvent } from '@mui/material';


interface IEDIConfigurer {
    customerUID: string;
    token: string;
}

const EDIConfigurer: FC<IEDIConfigurer> = ({ customerUID, token }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [selectedType, setSelectedType] = useState('');
    const [selectedFormat, setSelectedFormat] = useState<string>('');
    const [selectedFiles, setselectedFiles] = useState<File[]>([]);

    const { handleSubmit } = useForm();

    const getEdiConnector = async () => {
        let data = {
            customerUid: customerUID,
            connectorType: 'edi',
            configuration: {
                selectedFormat : selectedFormat,
                SendAcknowledgementSftp: false,
                receivingSftp: null,
                acknowledgementSftp: null
            }
        };
        setLoading(true);
        await post(apiConfig.addConnectorWithType(customerUID, 'edi'), data, token).then(() => {
            saveDefaultConnectorSchedule();
            setLoading(false);
            postEdiFile();
        });
    };

    const postEdiFile = async () => {
        if (!selectedFiles) {
            return;
        }

        const formData = new FormData();
        formData.append('files', selectedFiles[0]);
        formData.append('type', selectedType);

        setLoading(true);
        await postFile(apiConfig.uploadCustomerFile(customerUID), formData, token).then(
            (response: any) => {
                setLoading(false);
                if (response.status !== 200) {
                    setSuccess(false);
                    setError(true);
                } else {
                    setSuccess(true);
                    setTimeout(() => {
                        setSuccess(false);
                    }, 5000);
                    setError(false);
                    setSelectedType('');
                    setselectedFiles([]);
                }
            }
        );
    };

    const saveDefaultConnectorSchedule: () => void = async () =>
        await put(apiConfig.updateSchedule(customerUID), {
            schedule: '0 0 0 * * *'
        }, token);

    const fileDropHandler = (acceptedFiles: File[]) => {
        setselectedFiles(acceptedFiles);
    };

    const onSubmit = () => {
        getEdiConnector();
    };

    const options:ISelectData[] = [
        { value: 3, label: 'PurchaseOrders' }
    ];

    const handleChange = (selectedOption: any) => {
        setSelectedType(selectedOption);
    };

    const getSelectedName: (value: string) => string = (value) => {
        const getSelectedEdi: ISelectData = options.filter(
            (item: ISelectData) => item.value === value
        )[0];

        return getSelectedEdi?.label || '';
    };

    interface IEdiOptions {
        value: string;
        label: string;
    }

    const ediOptions: IEdiOptions[] = [
        { value: 'X12', label: 'X12' },
        { value: 'EDIFACT', label: 'Edifact' }
    ];

    const handleChangeFormat = (selectedOption: SelectChangeEvent<string>) => {
        setSelectedFormat(selectedOption.target.value as string);
    };

    const getSelectedFormat: (value: string) => string = (value) => {
        const getSelectedFotmat: IEdiOptions = ediOptions.filter(
            (item: IEdiOptions) => item.value === value
        )[0];

        return getSelectedFotmat?.label || '';
    };

    return loading ? (
        <LoaderInPage />
    ) : (
        <EDIConfigurerContainer
            onSubmit={(event) => {
                event.preventDefault();
                handleSubmit(onSubmit)();
            }}
        >
            {(error || success) && (
                <MessageContainer>
                    {error && (
                        <ErrorMessageContainer>
                            <PriorityHighIcon /> The file could not be uploaded. Please try again.
                        </ErrorMessageContainer>
                    )}
                    {success && (
                        <SuccessMessageContainer>
                            <CheckIcon /> The file has been uploaded successfully.
                        </SuccessMessageContainer>
                    )}
                </MessageContainer>
            )}
            <SelectContainer>
                <Select
                    label="Format"
                    background={themeColors.white}
                    menuItems={ediOptions}
                    value={selectedFormat}
                    disabled={loading}
                    name={getSelectedFormat(selectedFormat)}
                    placeholder="Edi Format"
                    changeHandler={(event: SelectChangeEvent<string>) => handleChangeFormat(event)}
                />
            </SelectContainer>
            <SelectContainer>
                <Select
                    label="Type"
                    background={themeColors.white}
                    menuItems={options}
                    value={selectedType}
                    name={getSelectedName(selectedType)}
                    placeholder="Select Type"
                    changeHandler={(event) => handleChange(event.target.value)}
                />
            </SelectContainer>
            <Uploader
                fileDropHandler={fileDropHandler}
                singlePreviewMode
                acceptedFileTypes={['x12', 'edifact']}
            />
            <PrimaryButton
                type="submit"
                width="fit-content"
                disabled={selectedFiles.length === 0 || selectedType === ''}
            >
                Save
            </PrimaryButton>
        </EDIConfigurerContainer>
    );
};

export default EDIConfigurer;
