/* eslint-disable react-hooks/exhaustive-deps */
import { apiConfig, get, post, put } from 'api/apiConfig';
import { CSSProperties, FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { useParams } from 'react-router';
import LoaderFullPage from 'components/LoaderFullPage';
import { stateCookieExists } from 'lib/cookie';
import connectorsConfig from '../../config/config';
import Select from 'react-select';
import { useForm } from 'react-hook-form';

const buttonStyles: CSSProperties = {
    display: 'flex',
    height: '40px',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '16px',
    border: '1px solid #E4E4E4',
    backgroundColor: '#FAFAFA',
    color: '#0032A0',
    fontSize: '16px',
    fontWeight: '600',
    borderRadius: '6px',
    cursor: 'pointer',
    marginTop: '16px'
};

const HeadlessConnectEnd: FC = () => {
    const params = useParams();
    const [searchParams] = useSearchParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [code, setCode] = useState<string | null>(null);
    const [location, setLocation] = useState<string | null>(null);
    const [tenantId, setTenantId] = useState<string | null>(null);
    const [tenants, setTenants] = useState<any[]>([]);
    const [isValidRequest, setIsValidRequest] = useState<boolean>(false);
    const [tenant, setTenant] = useState({ selectedOption: { value: '', label: '' } });
    const { register, handleSubmit, setValue } = useForm();

    useEffect(() => {
        getConnector();
    }, []);

    useEffect(() => {
        console.log(isValidRequest);
        if (isValidRequest) saveConnector();
    }, [isValidRequest]);

    const getConnector: () => void = async () => {
        setLoading(true);
        let redirectUrl = searchParams.get('redirectUrl');
        console.log(redirectUrl)
        if (!params.connectorUid || !params.customerUid || !redirectUrl) return;
    
        const getConnectorRes: Response = await get(
            apiConfig.getHeadlessConnector(params.customerUid, params.connectorUid),
            ''
        );

        if (!getConnectorRes.ok) {
            window.location.href = redirectUrl;
            return;
        }

        const getConnectorResJson = await getConnectorRes.json();

        if (getConnectorResJson.status == 'Active') {
            window.location.href = redirectUrl;
            return;
        }
        const code: string | null = searchParams.get('code');
        const state: string | null = searchParams.get('state');

        setCode(searchParams.get('code'));
        setTenantId(searchParams.get(connectorsConfig.connectors[getConnectorResJson.type]?.tenant_id));
        setLocation(searchParams.get(connectorsConfig.connectors[getConnectorResJson.type]?.location));
        console.log(code)
        console.log(stateCookieExists(state || ''))
        setIsValidRequest(stateCookieExists(state || '') && !!code);
    };
    
    const saveConnector: () => void = async () => {
        setLoading(true);
        let redirectUrl = searchParams.get('redirectUrl');
        if (!params.connectorUid || !params.customerUid || !redirectUrl) return;
        try {
            const addConnectorResponse: Response = await post(
                apiConfig.getHeadlessConnector(params.customerUid, params.connectorUid),
                {
                    code,
                    redirectUrl: `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                    tenantId,
                    location
                },
                ''
            );
            if (!addConnectorResponse.ok) {
                window.location.href = redirectUrl;
                return;
            }
            const addConnectorResponseJson = await addConnectorResponse.json();
            
            if (!addConnectorResponseJson.configuration.TenantId) {
                // Set tenant
                await getTenants();
                register('tenantId');
                setLoading(false);
            } else {
                window.location.href = redirectUrl;
            }
        } catch (error) {
            window.location.href = redirectUrl;
        }
    };
    
    const getTenants: () => Promise<void> = async () => {
        let redirectUrl = searchParams.get('redirectUrl');
        if (!params.connectorUid || !params.customerUid || !redirectUrl) return;
        const getTenantsResponse = await get(
            apiConfig.getHeadlessConnectorTenants(params.customerUid, params.connectorUid),
            '');

        if (!getTenantsResponse.ok) {
            window.location.href = redirectUrl;
            return;
        }

        const getTenantsResponseJson = await getTenantsResponse.json();

        console.log(getTenantsResponseJson)
        if (Object.keys(getTenantsResponseJson).length > 0) 
            setTenants(Object.keys(getTenantsResponseJson).map((key) => ({ value: key, label: getTenantsResponseJson[key] })));
        else
            window.location.href = redirectUrl;
    };

    const handleChange: (selectedOption: any, action: any) => void = (selectedOption, action) => {
        setValue(action.name, selectedOption.value);
        setTenant({ selectedOption });
    };

    const updateConnector: () => Promise<void> = async () => {
        let redirectUrl = searchParams.get('redirectUrl');
        if (!params.connectorUid || !params.customerUid || !redirectUrl) return;
        setLoading(true);

        try {
            const updateConnectorResponse = await put(
                apiConfig.getHeadlessConnector(params.customerUid, params.connectorUid),
                {
                    tenantId: tenant.selectedOption.value
                },
                ''
            );

            if (!updateConnectorResponse.ok) {
                window.location.href = redirectUrl;
            }

        } catch (error) {
            window.location.href = redirectUrl;
        } finally {
            window.location.href = redirectUrl;
        }
    };

    return loading ? (
        <LoaderFullPage />
    ) : (
        <div>
            {!loading && tenants && (
                <form className="form" onSubmit={handleSubmit(updateConnector)}>
                    <p style={{ marginBottom: '8px' }}>
                        We have configured your connector but we need to know which tenant to use
                        before it can be activated.
                    </p>
                    <Select
                        id="tenantId"
                        name="tenantId"
                        options={tenants}
                        placeholder="Tenant"
                        value={tenant.selectedOption}
                        onChange={handleChange}
                    />
                    <button style={buttonStyles} type="submit">
                        Submit
                    </button>
                </form>
            )}
        </div>
    );
};

export default HeadlessConnectEnd;
