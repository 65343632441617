import { AuthenticationResult, IPublicClientApplication } from '@azure/msal-browser';

export const authResult: (request: any, msalInstance: IPublicClientApplication) => Promise<AuthenticationResult | null> = async (
    request,
    msalInstance
) => {
    try {
        const authResult: AuthenticationResult = await msalInstance.acquireTokenSilent(request);
        return authResult;
    } catch (error) {
        return null;
    }
};