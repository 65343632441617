/* eslint-disable react-hooks/exhaustive-deps */
import { SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import ERPSelectionTiles from 'components/ERPSelectionTiles';
import LoaderInPage from 'components/LoaderInPage/LoaderInPage';
import Typography from 'components/typography';
import G2FSetup from 'lib/setup';
import { FC, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { ConnectionHeadingContainer, ConnectionsContainer } from './styled';
import { apiConfig, b2cPolicies, get } from 'api/apiConfig';
import { ConnectorType } from 'utils/interfaces/ConnectorType';
import { authResult } from 'lib/authConfig';
import { useNavigate } from 'react-router';

const Connection: FC = () => {
    const { instance } = useMsal();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const [token, setToken] = useState<string>('');
    const [tokenExpiry, setTokenExpiry] = useState<Date | null>();
    const [customerUid, setCustomerUid] = useState<string>('');
    const [partnerUid, setPartnerUid] = useState<string>('');
    const [connectionComplete, setConnectionComplete] = useState<boolean>(false);
    const [connectorTypes, setConnectorTypes] = useState<ConnectorType[]>([]);
    let count = 0;

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ],
        extraQueryParameters: {
            campaignId: window.location.hostname
        },
        authority: b2cPolicies.authorities.inviteRedeemPasswordless.authority
    };

    useEffect(() => {
        if (count === 0) {
            count++;
            setCustomerUid(searchParams.get('customerUid') || '');
            setPartnerUid(searchParams.get('partnerUid') || '');

            getToken();
        }
    }, []);

    useEffect(() => {
        if (!tokenExpiry) {
            return;
        }

        const timer = setTimeout(() => {
            navigate('/?unauthenticated=true');
        }, tokenExpiry.getTime() - Date.now());
            
        return () => {
            clearTimeout(timer);
        };
    }, [tokenExpiry]);

    const getToken: () => void = async () => {
        const token = await authResult(request, instance);
        if (!token) {
            navigate('/?unauthenticated=true');
        } else {
            setToken(token.accessToken);
            setTokenExpiry(token.expiresOn);
        }
    };

    useEffect(() => {
        if (customerUid && partnerUid && token) {
            getConnectorTypes();
        }
    }, [customerUid, partnerUid, token]);

    const getConnectorTypes: () => Promise<void> = async () => {
        const mappedData: Response = await get(
            apiConfig.getConnectorTypes(customerUid, partnerUid),
            token
        );

        if (!mappedData.ok) {
            return;
        }

        const mappedDataJson = await mappedData.json();
        setConnectorTypes(mappedDataJson);
    };

    const connectCompleteHandler: () => void = () => setConnectionComplete(true);

    return (
        <ConnectionsContainer>
            <ConnectionHeadingContainer>
                {connectionComplete && (
                    <Typography tag="h1" fontWeight="regular">
                        <Typography tag="h1" fontWeight="bold">
                            You have successfully connected the following accounting system<br></br>
                            and you can now close this browser tab.
                        </Typography>
                    </Typography>
                )}
                {!connectionComplete && (
                    <Typography tag="h1" fontWeight="bold">
                        Please wait while we determine the connection to your accounting system.
                    </Typography>
                )}
            </ConnectionHeadingContainer>

            {Boolean(customerUid) && Boolean(partnerUid) && Boolean(token) && connectorTypes && (
                <ERPSelectionTiles
                    setup={
                        new G2FSetup(
                            token,
                            `https://${window.spaSettings.g2fAuthRedirectUrl}/redirect`,
                            customerUid,
                            window.location.origin,
                            '',
                            connectorTypes,
                            partnerUid
                        )
                    }
                    connectionCompleteCallback={connectCompleteHandler}
                />
            )}
            {(!customerUid || !token) && <LoaderInPage size={20} />}
        </ConnectionsContainer>
    );
};

export default Connection;
