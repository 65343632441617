/* eslint-disable react-hooks/exhaustive-deps */
import { AuthenticationResult, SilentRequest } from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { apiConfig, put } from 'api/apiConfig';
import LoaderFullPage from 'components/LoaderFullPage';
import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router';

const SignUpStage2: FC = () => {
    const { instance } = useMsal();
    const params = useParams();
    const [loadingMessage, setLoadingMessage] = useState<string>('');
    let count = 0;

    const request: SilentRequest = {
        scopes: [
            `https://${window.spaSettings.tenantId}/${window.spaSettings.apiClientId}/CorporatePortal`
        ]
    };
    const authResult: () => Promise<string> = async () => {
        const authResult: AuthenticationResult = await instance.acquireTokenSilent(request);
        return authResult.accessToken;
    };

    useEffect(() => {
        if (count === 0) {
            initSignupSequence();
            count++;
        }
    }, []);

    const initSignupSequence: () => void = async () => {
        if (!params?.invitationId) return;
        setLoadingMessage('Accepting your invite...');

        const token: string = await authResult();

        if (!params?.invitationId) return;

        const acceptInviteResponse: Response = await put(
            apiConfig.acceptInvite(params.invitationId),
            null,
            token
        );

        acceptInviteResponse.ok
            ? setLoadingMessage('Invitation successfully accepted!')
            : setLoadingMessage('Something went wrong. Please contact us.');
    };

    return <LoaderFullPage detail={loadingMessage} />;
};

export default SignUpStage2;
