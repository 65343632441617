import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const InvitationContainer = styled(G2FSSContainer)`
    flex: 1;
    justify-content: space-between;
`;

export const InvitationSectionContainer = styled(G2FSSContainer)``;

export const InvitationReportContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
`;

export const InvitationButtonContainer = styled.div`
    display: flex;
    width: 130px;
`;
