import { G2FSSContainer } from 'components/common/styled';
import styled from 'styled-components';

export const ConnectionsContainer = styled(G2FSSContainer)``;

export const ConnectionHeadingContainer = styled(G2FSSContainer)`
    margin-bottom: 24px;
`;

export const ConnectionReportContainer = styled.div`
    display: flex;
    flex-direction: row;
    gap: 16px;
    align-items: center;
    width: 100%;
`;
