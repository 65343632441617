import { PublicClientApplication } from '@azure/msal-browser';
import { MsalProvider } from '@azure/msal-react';
import Footer from 'components/footer';
import Nav from 'components/nav';
import Layout from 'layout';
import { FC, Suspense, useEffect } from 'react';
import { Navigate, Route, Routes, useNavigate } from 'react-router';
import { AppWrapper, ViewWrapper } from 'styled';
import Accept from 'views/Accept';
import Connection from 'views/Connection';
import Connections from 'views/Connections';
import Customer from 'views/Customer';
import LandingPage from 'views/Extension';
import Invitation from 'views/Invitation';
import NimblaWelcome from 'views/NimblaWelcome';
import SecureAppWrapper from 'views/SecureAppWrapper';
import HeadlessConnectStart from 'views/Headless/HeadlessConnectStart';
import HeadlessConnectEnd from 'views/Headless/HeadlessConnectEnd';
import SignIn from 'views/SignIn';
import SignUp from 'views/SignUp';
import SignUpStage2 from 'views/SignUp/SignUpStage2';
import TermsAndConditions from 'views/TermsAndConditions';

export type MSALPropType = {
    msalInstance: PublicClientApplication;
    immediateRedirect?: string;
};

const App: FC<MSALPropType> = ({ msalInstance, immediateRedirect }) => {
    const navigate = useNavigate();

    useEffect(() => {
        if (immediateRedirect && immediateRedirect?.length > 0) navigate(immediateRedirect);
    }, [immediateRedirect, navigate]);

    return (
        <MsalProvider instance={msalInstance}>
            <AppWrapper>
                <header>
                    <Nav />
                </header>
                <ViewWrapper>
                    <Layout>
                        <Suspense fallback={<h1>Loading...</h1>}>
                            <Routes>
                                <Route
                                    path="/app"
                                    element={<SecureAppWrapper />}
                                >
                                    <Route
                                        path="accept/:partnerUid"
                                        element={<Accept />}
                                    />
                                    <Route path="customer/:linkUid" element={<Customer />} />
                                    <Route
                                        path="connection"
                                        element={<Connection />}
                                    />
                                    <Route path="connections" element={<Connections />} />
                                    <Route
                                        path="signup/stage2/:invitationId"
                                        element={<SignUpStage2 />}
                                    />
                                </Route>
                                <Route path="/accept/:invitationId" element={<SignUp />} />
                                <Route path="/invitation" element={<Invitation />} />
                                <Route path="/nimblawelcome" element={<NimblaWelcome />} />
                                <Route path="/landingpage" element={<LandingPage />} />

                                <Route path="/headless">
                                    <Route path="customers/:customerUid/connector/:connectorUid/start" element={<HeadlessConnectStart />} />
                                    <Route path="customers/:customerUid/connector/:connectorUid/end" element={<HeadlessConnectEnd />} />
                                </Route>
                                <Route
                                    path="/termsandconditions"
                                    element={<TermsAndConditions />}
                                />
                                <Route path="/" element={<SignIn />} />
                                <Route path="*" element={<Navigate to="/" />} />
                            </Routes>
                        </Suspense>
                    </Layout>
                </ViewWrapper>
                <footer>
                    <Footer />
                </footer>
            </AppWrapper>
        </MsalProvider>
    );
};

export default App;
